import { DefaultStruct } from '@App/Structures';
import { ERangeType, RangeSelector } from '@App/Component/RangeSelector';

export namespace SensorsModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            locationId ?: number,
            groupId ?: number,
            port ?: string,
            alerts ?: number,
            priority ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            location_id ?: number,
            group_id ?: number,
            port_id ?: string,
            alerts ?: number,
            priority ?: number,
            qstr ?: string,
        }
        export function getQuery(query : IQuery) : IQueryOutput {
            const output : IQueryOutput = {};
            if(query.page) output.page = query.page;
            if(query.qstr) output.qstr = query.qstr;
            if(query.limit) output.limit = query.limit;
            if(query.orderBy) output.orderBy = query.orderBy;
            if(query.sort) output.sort = query.sort;
            if(query.locationId) output.location_id = query.locationId;
            if(query.groupId) output.group_id = query.groupId;
            if(query.port) output.port_id = query.port;
            if(query.alerts !== null) output.alerts = query.alerts;
            if(query.priority) output.priority = query.priority;
            return output;
        }
    }

    export namespace Export {
        export interface IPayload {
            from ?: number,
            to ?: number,
        }
    }

    export namespace Stats {
        export interface IQuery {
            limit ?: number,
            from ?: number,
            to ?: number,
        }
    }

    export namespace Events {
        export interface IQuery extends DefaultStruct.IQuery {
            dateRange ?: ERangeType,
            dateFrom ?: number,
            dateTo ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            from ?: number,
            to ?: number,
        }
        export function getQuery(source : IQuery) : IQueryOutput {
            const query : IQueryOutput = {
                ...RangeSelector.getDateQuery(source),
            };
            if(source.page) query.page = Math.abs(source.page) || 1;
            if(source.limit) query.limit = Math.abs(source.limit) || 50;
            if(source.orderBy) query.orderBy = source.orderBy;
            if(source.sort) query.sort = source.sort == 'asc' ? 'asc' : 'desc';
            return query;
        }
    }

    export namespace Settings {
        export interface IPayload {
            iconId : number,
            name : string,
            priorityId : number,
            isActive : boolean,
            groupId : number,
            locationId : number,
            notes : string,
        }
        export interface IPayloadOutput {
            icon_id : string,
            name : string,
            priority : string,
            active : string,
            group_id : string,
            location_id : string,
            notes : string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            return {
                icon_id: String(payload.iconId) || '1',
                name: payload.name || '',
                priority: payload.priorityId ? String(payload.priorityId) : '3',
                active: payload.isActive ? '1' : '0',
                group_id: payload.groupId ? String(payload.groupId) : null,
                location_id: payload.locationId ? String(payload.locationId) : null,
                notes: payload.notes || '',
            };
        }
    }

}