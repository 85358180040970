import React from 'react';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { UserModel } from '@App/Service/Users/Model';
import { CreateFormStore } from '@App/Service/Users/Create/CreateFormStore';
import { Form } from '@Framework/Library/Form';
import { InputField, SelectField, SwitchField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { Session } from '@Framework/Core/Session';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';

interface IProps {}
interface IState {
    saving : boolean,
}

@observer
export class Create extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : CreateFormStore = new CreateFormStore();

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.form.save();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="users">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item"><Link url="/users"><a>Users</a></Link></li>
                                    <li className="breadcrumb-item active">Create</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header"><h3 className="m-0">Create</h3></div>
                                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.name} />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Alert Daily</label>
                                                    <SwitchField disabled={this.state.saving} store={this.form.fields.alertDaily} wide={true} />
                                                </div>
                                            </div>
                                        </div>
                                        {Session.user.role.id == 1 &&
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Role</label>
                                                        <SelectField disabled={this.state.saving} store={this.form.fields.roleId}>
                                                            <option value="1">Super Admin</option>
                                                            <option value="2">Admin</option>
                                                            <option value="3">User</option>
                                                        </SelectField>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Is active</label>
                                                        <SwitchField disabled={this.state.saving} store={this.form.fields.isActive} wide={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.email} />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Phone</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.phone} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Alert SMS</label>
                                                    <div className="field-group">
                                                        <SwitchField disabled={this.state.saving} store={this.form.fields.alertSMS} />
                                                        <InputField disabled={this.state.saving} store={this.form.fields.alertSMSTime} wrapperClass="w-100" />
                                                        <span className="field-group-text">Min</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Alert Email</label>
                                                    <div className="field-group">
                                                        <SwitchField disabled={this.state.saving} store={this.form.fields.alertEmail} />
                                                        <InputField disabled={this.state.saving} store={this.form.fields.alertEmailTime} wrapperClass="w-100" />
                                                        <span className="field-group-text">Min</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <InputField type="password" disabled={this.state.saving} store={this.form.fields.password} />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="form-group">
                                                    <label>Confirm password</label>
                                                    <InputField type="password" disabled={this.state.saving} store={this.form.fields.passwordConfirm} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col"/>
                                            <div className="col-auto">
                                                <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                                    {this.state.saving
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-check" />
                                                    } Create
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ saving: true });
        (async () => {
            const res = await UserModel.create(this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully created.',
                });
                App.redirect(`/users/${res.payload.data.id}`);
            }
            this.setState({ saving: true });
        })();
    }

}