import React from 'react';
import { SettingsFormStore } from '@App/Service/Sensors/Details/Settings/SettingsFormStore';
import { Details, EStatus as EContextStatus, ETab, SensorContext } from '@App/Service/Sensors/Details/Details';
import { Loading } from '@Framework/Component/Loading';
import { SettingsForm } from '@App/Service/Sensors/Details/Settings/SettingsForm';
import { SensorsModel, SensorsModelRes } from '@App/Service/Sensors/Model';
import { TagsModel } from '@App/Service/Tags/Model';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { Reply } from '@Framework/Library/Gateway';
import { DefaultStruct } from '@App/Structures';
import { CreateTagModal } from '@App/Service/Tags';

export enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    id : number,
}
interface IState {
    status : EStatus,
    saving : boolean,
    groups : DefaultStruct.IOption[],
    locations : DefaultStruct.IOption[],
    addTag : EAddTag,
}
enum EAddTag {
    Idle,
    Group,
    Location,
}

export class Settings extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : SettingsFormStore = new SettingsFormStore();
    private onUpdate : Function;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            saving: false,
            groups: [],
            locations: [],
            addTag: EAddTag.Idle,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        const status = this.state.status;
        return (
            <Details id={this.props.id} tab={ETab.Settings} onLoad={data => this.form.fill(data).save()} onUpdate={func => this.onUpdate = func}>
                <SensorContext.Consumer>
                    {sensor => (
                        <>
                            {(sensor.status != EContextStatus.Loaded || status != EStatus.Loaded) &&
                                <div className="card-body">
                                    {(sensor.status == EContextStatus.Failed || status == EStatus.Failed)
                                        ? <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error</div>
                                        : <Loading label="Loading..." />
                                    }
                                </div>
                            }
                            {(sensor.status == EContextStatus.Loaded && status == EStatus.Loaded) &&
                                <SettingsForm
                                    form={this.form}
                                    saving={this.state.saving}
                                    groups={this.state.groups}
                                    locations={this.state.locations} onSubmit={() => this.onSubmit()}
                                    onAddTag={type => this.setState({ addTag: type == 'groups' ? EAddTag.Group : EAddTag.Location })}
                                />
                            }
                        </>
                    )}
                </SensorContext.Consumer>
                {this.state.addTag != EAddTag.Idle &&
                <CreateTagModal
                    type={this.state.addTag == EAddTag.Group ? 'groups' : 'locations'}
                    onCreated={id => this.onTagCreated(id)}
                    onClose={() => this.setState({ addTag: EAddTag.Idle })}
                />
                }
            </Details>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        (async () => {
            this.setState({ saving: true });
            const res = await SensorsModel.update(this.props.id, this.form.getValues());
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.form.save();
                if(this.onUpdate) this.onUpdate(res.payload.data);
                App.notification({
                    type: ENotificationType.Success,
                    message: 'Successfully saved.',
                });
            }
            this.setState({ saving: false });
        })();
    }

    private onTagCreated(id : number) : void {
        (async () => {
            const type = this.state.addTag == EAddTag.Group ? 'groups' : 'locations';
            const res = await TagsModel.list(type, { limit: 500 });
            if(res.success) {
                const values = this.form.getValues();
                if(type == 'groups') {
                    this.setState({ groups: res.payload.data, addTag: EAddTag.Idle });
                    values.groupId = id;
                } else {
                    this.setState({ locations: res.payload.data, addTag: EAddTag.Idle });
                    values.locationId = id;
                }
                this.form.fill(values);
            }
        })();
    }

    private loadData() : void {
        this.setState({ status: EStatus.Loading });
        let loaded = 0;
        const onLoaded = (res : Reply<SensorsModelRes.Relations.IPayload>, field : 'groups' | 'locations') => {
            loaded++;
            if(!this.isMount) return;
            if(res.success) {
                const keys = {};
                keys[field] = res.payload.data || [];
                this.setState(state => ({
                    ...keys,
                    ...loaded >= 2 && state.status != EStatus.Failed ? { status: EStatus.Loaded } : null,
                }));
            } else this.setState({ status: EStatus.Failed });
        };
        SensorsModel
            .groups()
            .then(res => onLoaded(res, 'groups'));
        SensorsModel
            .locations()
            .then(res => onLoaded(res, 'locations'));
    }

}