import React from 'react';
import { Map } from 'subway-router';
import { TLoadService } from '@Framework/Core/App';
import { LoggedIn } from '@App/Middleware';
import { Events, List, Settings, Stats, TriggerCreate, TriggerDetails, TriggersList } from '@App/Service/Sensors';
import { ERangeType, RangeSelector } from '@App/Component/RangeSelector';

export function register(map : Map, loadService : TLoadService) {
    map.group('/sensors?', group => {
        group.route('/', res => {
            loadService(<List
                page={Number(res.key('page')) || 1}
                limit={Number(res.key('limit')) || 50}
                orderBy={res.key('orderBy') || undefined}
                sort={res.key('sort') || undefined}
                locationId={Number(res.key('locationId')) || null}
                groupId={Number(res.key('groupId')) || null}
                port={res.key('port') || ''}
                alerts={res.key('alerts') == '' ? null : Number(res.key('alerts')) || 0}
                priority={Number(res.key('priority')) || null}
            />);
        });
        group.group('/{id:i}', group => {
            group.route('/', res => {
                loadService(<Stats
                    id={Number(res.segment(2))}
                    rangeType={RangeSelector.stringToRangeType(res.key('rangeType')) || ERangeType.Today}
                    dateFrom={res.key('dateFrom') ? Number(res.key('dateFrom')) : undefined}
                    dateTo={res.key('dateTo') ? Number(res.key('dateTo')) : undefined}
                />);
            });
            group.route('/events', res => {
                loadService(<Events
                    id={Number(res.segment(2))}
                    page={Number(res.key('page')) || 1}
                    limit={Number(res.key('limit')) || 50}
                    orderBy={res.key('orderBy') || undefined}
                    sort={res.key('sort') || undefined}
                    rangeType={RangeSelector.stringToRangeType(res.key('rangeType'))}
                    dateFrom={res.key('dateFrom') ? Number(res.key('dateFrom')) : undefined}
                    dateTo={res.key('dateTo') ? Number(res.key('dateTo')) : undefined}
                />);
            });
            group.route('/settings', res => {
                loadService(<Settings
                    id={Number(res.segment(2))}
                />);
            });
            group.group('/triggers', group => {
                group.route('/', res => {
                    loadService(<TriggersList
                        sensorId={Number(res.segment(2))}
                        page={Number(res.key('page')) || 1}
                        limit={Number(res.key('limit')) || 50}
                        orderBy={res.key('orderBy') || undefined}
                        sort={res.key('sort') || undefined}
                    />);
                });
                group.route('/create', res => {
                    loadService(<TriggerCreate
                        sensorId={Number(res.segment(2))}
                    />);
                });
                group.route('/{id:i}', res => {
                    loadService(<TriggerDetails
                        sensorId={Number(res.segment(2))}
                        id={Number(res.segment(4))}
                    />);
                });
            });
        });
    }).name('sensors').middleware(new LoggedIn(loadService));
}