import React from 'react';
import { Map } from 'subway-router';
import { TLoadService } from '@Framework/Core/App';
import { LoggedIn } from '@App/Middleware';
import { CreateSets, DetailsSets, ListSets } from '@App/Service/Sets/index';

export function register(map : Map, loadService : TLoadService) {
    map.group('/sets', group => {
        group.route('/', res => {
            loadService(<ListSets
                page={Number(res.key('page')) || 1}
                limit={Number(res.key('limit')) || 50}
                orderBy={res.key('orderBy') || undefined}
                sort={res.key('sort') || undefined}
                // status={Number(Location.param('status')) || null}
            />);
        });
        group.route('/create', res => {
            loadService(<CreateSets />);
        });
        group.route('/{id:i}', res => {
            loadService(<DetailsSets
                id={Number(res.segment(2))}
            />);
        });
    }).name('sets').middleware(new LoggedIn(loadService));
}