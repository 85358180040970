import React from 'react';
import { Session, UserContext } from '@Framework/Core/Session';
import { Loading } from '@Framework/Component/Loading';
import { App } from '@Framework/Core/App';
import '@App/Layout/MainLayout/UserBar/UserBar.less';
import { OnClickOutside } from '@Framework/Factory';
import { AuthModel } from '@App/Service/Auth/Model';
import { Location } from '@Framework/Core/Location';
import { Model } from '@Framework/Library/Gateway';

interface IProps {}
interface IState {
    openCompanies : boolean,
    openProfile : boolean,
    updating : boolean,
}

export class UserBar extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            openCompanies: false,
            openProfile: false,
            updating: false,
        };
    }

    public render() : React.ReactNode {
        return (
            <UserContext.Consumer>
                {user => (
                    <div id="user-bar">
                        <div className="row">
                            {user.companies.length > 1 &&
                                <div className="col-auto">
                                    <div id="user-companies" className={this.state.updating ? 'disabled' : undefined} onClick={() => this.setState(state => ({ openCompanies: !state.openCompanies }))}>
                                        <div>
                                            {this.state.updating ? <span className="spinner-border spinner-border-sm" /> : <i className="fa fa-bank" />} {UserBar.getCompanyName(user.companies.find(item => item.id == user.companyId).name)}
                                        </div>
                                        {(this.state.openCompanies && !this.state.updating) &&
                                            <OnClickOutside topLevelId="user-companies" onClick={() => this.setState({ openCompanies: false })}>
                                                <ul className="dropdown-menu show">
                                                    {user.companies.map(item => (
                                                        <li key={item.id} className={`dropdown-item${item.id == user.companyId ? ' active' : ''}`} onClick={() => this.switchCompany(item.id)}>
                                                            {item.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </OnClickOutside>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col px-0" />
                            <div className="col-auto">
                                <div id="user-profile" onClick={() => this.setState(state => ({ openProfile: !state.openProfile }))}>
                                    <div>
                                        {user.id > 0
                                            ? (
                                                <div className="text-end">
                                                    <div className="name">{user.name}</div>
                                                    <div className="role">{user.role.name}</div>
                                                </div>
                                            )
                                            : <Loading small={true} light={true} />
                                        }
                                    </div>
                                    <div>
                                        <div className="avatar" />
                                    </div>
                                    {this.state.openProfile &&
                                        <OnClickOutside topLevelId="user-profile" onClick={() => this.setState({ openProfile: false })}>
                                            <ul className="dropdown-menu show">
                                                <li className="dropdown-item" onClick={UserBar.logout}>Logout</li>
                                                {MODE !== 'production' && <li className="dropdown-item" onClick={UserBar.expire}>Expire (test)</li>}
                                            </ul>
                                        </OnClickOutside>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </UserContext.Consumer>
        );
    }

    private switchCompany(id : number) : void {
        this.setState({ updating: true });
        (async () => {
            if((await AuthModel.setCompany(id)).success) {
                const res = await AuthModel.user();
                if(res.success) Session.setUser(res.payload, () => {
                    Model.configure({
                        resources: [{
                            origins: res.payload.instances.map(
                                item => MAIN_API_HOST
                                    .replace('{instance}', String(item.id))
                                    .replace('{port}', item.port != 443 ? `:${String(item.port)}` : ''),
                            ),
                            defaults: {
                                auth: true,
                                hybridStatusCode: true,
                            },
                        }, {
                            origins: AUTH_API_HOSTS,
                            defaults: {
                                auth: true,
                                hybridStatusCode: true,
                            },
                        }],
                    });
                    Location.path == '/sensors' || Location.path == '/' ? App.refresh() : App.redirect('/sensors');
                });
            }
            this.setState({ updating: false });
        })();
    }

    private static logout() : void {
        (async () => {
            await AuthModel.logout();
            Session.destroy();
            App.redirect('/auth/login');
        })();
    }

    private static expire() : void {
        (async () => {
            await AuthModel.expire();
            App.route();
        })();
    }

    private static getCompanyName(source : string) : string {
        if(source.length > 12) {
            return `${source.slice(0, 9)}...`;
        } else {
            return source;
        }
    }

}