export namespace DefaultStruct {

    export interface IOption {
        id : number,
        name : string,
    }
    export interface IOptionSource {
        id : string,
        name : string,
    }
    export function getOption(source : IOptionSource) : IOption {
        try {
            return {
                id: Number(source.id),
                name: source.name || '',
            };
        } catch {
            return null;
        }
    }

    export interface IQuery {
        qstr ?: string,
        q ?: string,
        page ?: number,
        limit ?: number,
        orderBy ?: string,
        sort ?: string,
    }
    export interface IQueryOutput {
        page ?: number,
        limit ?: number,
        orderBy ?: string,
        sort ?: string,
    }
    export function getQuery(source : IQuery) : IQueryOutput {
        const query : IQueryOutput = {};
        if(source.page) query.page = Math.abs(source.page) || 1;
        if(source.limit) query.limit = Math.abs(source.limit) || 50;
        if(source.orderBy) query.orderBy = source.orderBy;
        if(source.sort) query.sort = source.sort == 'asc' ? 'asc' : 'desc';
        return query;
    }

    export interface IPagination {
        limit : number,
        page : number,
        pages : number,
        total : number,
    }
    export interface IPaginationSource {
        limit : number,
        page : number,
        pages : number,
        total : string,
    }
    export function getPagination(source : IPaginationSource) : IPagination {
        try {
            return {
                limit: Number(source.limit) || 25,
                page: Number(source.page) || 1,
                pages: Number(source.pages) || 1,
                total: Number(source.total) || 0,
            };
        } catch {
            return null;
        }
    }

    export namespace Sensors {
        export interface IValueType extends IOption {
            sign : string,
        }
        export const valueTypes : IValueType[] = [
            { id: 1, name: 'Temperature (C)', sign: 'C' },
            { id: 2, name: 'Humidity (%)', sign: '%' },
            { id: 3, name: 'Percentage (%)', sign: '%' },
        ];
        export function getValueType(id : number) : IValueType {
            return id ? valueTypes.find(t => t.id == id) : null;
        }
    }

    export namespace Triggers {
        export interface IType extends IOption {}
        export const types : IType[] = [
            { id: 1, name: 'Max value exceeded' },
            { id: 2, name: 'Min value exceeded' },
            { id: 3, name: 'Value equals' },
            { id: 4, name: 'Value raised' },
            { id: 5, name: 'Value dropped' },
            { id: 6, name: 'Value changed' },
            { id: 7, name: 'Timeout' },
        ];
        export function getType(id : number) : IType {
            return id ? types.find(t => t.id == id) : null;
        }
    }

}