import { SensorTriggersStruct } from '@App/Service/Sensors/Details/Triggers/Model';
import { DefaultStruct } from '@App/Structures';

export namespace SensorTriggersRes {

    export namespace List {
        export interface IPayload {
            data : SensorTriggersStruct.ITrigger[],
            pagination : DefaultStruct.IPagination,
        }
        export interface IPayloadSource {
            data : SensorTriggersStruct.ITriggerSource[],
            paginator : DefaultStruct.IPaginationSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    id: Number(item.id) || 0,
                    name: item.name || '',
                    idx: item.sensor_idx || '',
                    timeLimit: Number(item.time_limit) || 0,
                    simpleValue: !item.value_json || item.value_json == '{}',
                    valueNum: Number(item.value_num) || 0,
                    valueJson: item.value_json || null,
                    typeId: Number(item.type) || 0,
                    type: DefaultStruct.Triggers.getType(Number(item.type)),
                    level: Number(item.level) || 0,
                    isActive: item.active == '1',
                })),
                pagination: DefaultStruct.getPagination(source.paginator) || null,
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : SensorTriggersStruct.ITrigger,
        }
        export interface IPayloadSource {
            data : SensorTriggersStruct.ITriggerSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: {
                    id: Number(source.data.id) || 0,
                    name: source.data.name || '',
                    idx: source.data.sensor_idx || '',
                    timeLimit: Number(source.data.time_limit) || 0,
                    simpleValue: !source.data.value_json,
                    valueNum: Number(source.data.value_num) || 0,
                    valueJson: source.data.value_json || null,
                    typeId: Number(source.data.type) || 0,
                    type: DefaultStruct.Triggers.getType(Number(source.data.type)),
                    level: Number(source.data.level) || 0,
                    isActive: source.data.active == '1',
                },
            };
        }
    }

}