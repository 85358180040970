import React from 'react';
import { EventsModel, EventsModelStruct } from '@App/Service/Events/Model';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { Loading } from '@Framework/Component/Loading';
import { Pagination } from '@Framework/Component/Pagination';
import { Location } from '@Framework/Core/Location';
import { DefaultStruct } from '@App/Structures';
import { ERangeType, IRangeProps, RangeSelector } from '@App/Component/RangeSelector';
import moment from 'moment';
import { App } from '@Framework/Core/App';
import { Format } from '@App';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    page ?: number,
    limit ?: number,
    orderBy ?: string,
    sort ?: string,
    rangeType : ERangeType,
    dateFrom : number,
    dateTo : number,
}
interface IState {
    status : EStatus,
    data : EventsModelStruct.IEvent[],
    pagination : DefaultStruct.IPagination,
}

export class List extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: [],
            pagination: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.page != prevProps.page ||
            this.props.limit != prevProps.limit ||
            this.props.orderBy != prevProps.orderBy ||
            this.props.sort != prevProps.sort ||
            this.props.rangeType != prevProps.rangeType ||
            (this.props.rangeType == ERangeType.Custom && this.props.dateFrom != prevProps.dateFrom) ||
            (this.props.rangeType == ERangeType.Custom && this.props.dateTo != prevProps.dateTo)
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="events">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item active">Events</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col"></div>
                                        <div className="col-auto">
                                            <RangeSelector { ...this.props } onChange={props => List.onRangeChange(props)} />
                                        </div>
                                        {this.props.rangeType !== null &&
                                            <div className="col-auto">
                                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => List.onRangeChange({ rangeType: null })}>
                                                    <i className="fa fa-undo" /> All time
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {this.state.status != EStatus.Loaded &&
                                    <div className="card-body">
                                        {this.state.status == EStatus.Loading && <Loading />}
                                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error...</div>}
                                    </div>
                                }
                                {this.state.status == EStatus.Loaded &&
                                    <>
                                        <div className="card-body p-0 d-none d-md-block">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-wide m-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Sensor</th>
                                                            <th>Date</th>
                                                            <th>Recovered</th>
                                                            <th>Message</th>
                                                            <th>Value</th>
                                                            <th>Hits</th>
                                                            <th/>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.data.map(item => (
                                                            <tr key={item.id} className={!item.isResolved ? 'bg-danger' : undefined}>
                                                                <td>{item.sensor.name}</td>
                                                                <td>{moment.unix(item.tmAdd || item.tm).format(`${Format.Date.YN} ${Format.Time.S}`)}</td>
                                                                <td>{(item.isResolved && item.tmRec) && moment.unix(item.tmRec).format(`${Format.Date.YN} ${Format.Time.S}`)}</td>
                                                                <td>{item.message}</td>
                                                                <td>{item.value && `${item.value}${item.valueType.sign}`}</td>
                                                                <td>{item.hits}</td>
                                                                <td className="text-end"><Link url={`/sensors/${item.sensor.idx}`}><a className="btn btn-xs btn-primary"><i className="fa fa-external-link" /> Open sensor</a></Link></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body py-0 d-md-none">
                                            {this.state.data.map(item => (
                                                <div key={item.id} className="item-block">
                                                    <div className="item-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <strong>{item.sensor.name}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-1">
                                                            <div className="col">
                                                                <span className="badge bg-secondary">Created<br/>{moment.unix(item.tmAdd || item.tm).format(`${Format.Date.YN} ${Format.Time.S}`)}</span>
                                                            </div>
                                                            <div className="col text-end">
                                                                {(item.isResolved && item.tmRec)
                                                                    ? <span className="badge bg-danger">Not recovered</span>
                                                                    : <span className="badge bg-success">Recovered<br/>{moment.unix(item.tmRec).format(`${Format.Date.YN} ${Format.Time.S}`)}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <span className="prop">Value:</span>
                                                                {' '}
                                                                {item.value && `${item.value}${item.valueType.sign}`}
                                                            </div>
                                                            <div className="col text-end">
                                                                <span className="prop">Hits:</span>
                                                                {' '}
                                                                {item.hits}
                                                            </div>
                                                        </div>
                                                        {item.message &&
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="prop">Message:</div>
                                                                    {item.message}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="item-controls text-end">
                                                        <Link url={`/sensors/${item.sensor.idx}`}><a className="btn btn-xs btn-primary"><i className="fa fa-external-link" /> Open sensor</a></Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="card-footer">
                                            <Pagination limit={this.props.limit} { ...this.state.pagination } />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await EventsModel.list(this.props);
            if(!this.isMount) return;
            if(res.success) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                    pagination: res.payload.pagination,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private static onRangeChange(props : IRangeProps) : void {
        const query = { ...Location.params };
        if(props.rangeType == null) {
            delete query.rangeType;
            delete query.dateFrom;
            delete query.dateTo;
        } else {
            query.rangeType = String(props.rangeType);
            if(props.rangeType == ERangeType.Custom) {
                query.dateFrom = String(props.dateFrom);
                query.dateTo = String(props.dateTo);
            }
        }
        query.page = '1';
        App.redirect(`/events?${Location.buildQuery(query)}`);
    }

}