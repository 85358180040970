import React from 'react';
import { IMiddleware, Request, Route, TOnLoad } from 'subway-router';
import { Session } from '@Framework/Core/Session';
import { AuthModel } from '@App/Service/Auth/Model/AuthModel';
import { App } from '@Framework/Core/App';
import { LoadingScreen } from '@App/SystemPage';
import { Model } from '@Framework/Library/Gateway';

export class Userdata implements IMiddleware {

    public async onResolving(onLoad : TOnLoad, request : Request, route : Route) : Promise<TOnLoad> {
        if(!route.inGroup('auth') && Session.isActive && Session.user && Session.user.id < 1) {
            App.setView(<LoadingScreen />);
            const user = await AuthModel.user();
            const predefinedCompanyId = location.host.match(/^\d{4}\.secure\./i)
                ? Number(location.host.split('.')[0])
                : 0;
            if(user.success && user.payload) {
                // if(predefinedCompanyId) {
                //     const company = await AuthModel.companyDetails(Number(predefinedCompanyId));
                //     if(company.success && company.payload) {
                //         user.payload.instance = company.payload.data.instance;
                //         user.payload.companyId = company.payload.data.id;
                //         user.payload.companies.unshift({
                //             id: company.payload.data.id,
                //             name: company.payload.data.name,
                //         });
                //     }
                // }
                const company = await AuthModel.companyDetails(
                    predefinedCompanyId
                        ? predefinedCompanyId
                        : user.payload.companyId,
                );
                await new Promise(resolve => Session.setUser(user.payload, resolve));
                Model.configure({
                    resources: [{
                        origins: company.payload.data.instances.map(
                            item => MAIN_API_HOST
                                .replace('{instance}', String(item.id))
                                .replace('{port}', item.port != 443 ? `:${String(item.port)}` : ''),
                        ),
                        defaults: {
                            auth: true,
                            hybridStatusCode: true,
                        },
                    }, {
                        origins: AUTH_API_HOSTS,
                        defaults: {
                            auth: true,
                            hybridStatusCode: true,
                        },
                    }],
                });
            }
        }
        return onLoad;
    }

}