import React from 'react';
import { SensorTriggersModel } from '@App/Service/Sensors/Details/Triggers/Model';
import { TriggerFormStore } from '@App/Service/Sensors/Details/Triggers/TriggerFormStore';
import { Details as SensorDetails, ETab } from '@App/Service/Sensors/Details/Details';
import { TriggerForm } from '../TriggerForm';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';

interface IProps {
    sensorId : number,
}
interface IState {
    saving : boolean,
}

export class Create extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : TriggerFormStore = new TriggerFormStore();

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.form.fields.level.fill(1);
        this.form.fields.simpleValue.fill(1);
        this.form.save();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <SensorDetails id={this.props.sensorId} tab={ETab.Triggers}>
                <TriggerForm
                    form={this.form}
                    saving={this.state.saving}
                    onSubmit={() => this.onSubmit()}
                />
            </SensorDetails>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        (async () => {
            this.setState({ saving: true });
            const res = await SensorTriggersModel.create(this.props.sensorId, this.form.getValues());
            if(!this.isMount) return;
            if(res.success && res.payload) {
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully created.',
                });
                App.redirect(`/sensors/${this.props.sensorId}/triggers`);
            } else this.setState({ saving: false });
        })();
    }

}