import React from 'react';
import { TriggerFormStore } from '@App/Service/Sensors/Details/Triggers/TriggerFormStore';
import { InputField, SelectField, SwitchField, TextField } from '@Framework/Component/FormField';
import { Form } from '@Framework/Library/Form';
import { DefaultStruct } from '@App/Structures';
import { observer } from 'mobx-react';

interface IProps {
    form : TriggerFormStore,
    saving : boolean,
    locked ?: boolean,
    onSubmit : Function,
    controls ?: React.ReactNode,
}
interface IState {}

@observer
export class TriggerForm extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        return (
            <Form store={this.props.form} onSubmit={() => this.props.onSubmit()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-6 col-md-9">
                            <div className="form-group">
                                <label>Name</label>
                                <InputField disabled={this.props.saving || this.props.locked} store={this.props.form.fields.name} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <label>Active</label>
                                <SwitchField disabled={this.props.saving || this.props.locked} store={this.props.form.fields.isActive} wide={true} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <label>Type</label>
                                <SelectField disabled={this.props.saving || this.props.locked} store={this.props.form.fields.typeId}>
                                    <option value=""></option>
                                    {DefaultStruct.Triggers.types.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </SelectField>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <label>Level</label>
                                <SelectField disabled={this.props.saving || this.props.locked} store={this.props.form.fields.level}>
                                    <option value="1">Notice</option>
                                    <option value="2">Warning</option>
                                    <option value="3">Fatal</option>
                                </SelectField>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <label>Time limit</label>
                                <InputField disabled={this.props.saving || this.props.locked} store={this.props.form.fields.timeLimit} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <label>Simple value</label>
                                <SwitchField disabled={this.props.saving || this.props.locked} store={this.props.form.fields.simpleValue} wide={true} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                {this.props.form.fields.simpleValue.value == '1'
                                    ? <>
                                        <label>Number value</label>
                                        <InputField disabled={this.props.saving || this.props.locked} store={this.props.form.fields.valueNum} />
                                    </>
                                    : <>
                                        <label>JSON value</label>
                                        <TextField disabled={this.props.saving || this.props.locked} store={this.props.form.fields.valueJson} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row align-items-center">
                        <div className="col">{this.props.controls ? this.props.controls : null}</div>
                        <div className="col-auto">
                            {!this.props.form.isValid && <div className="form-notice text-danger"><i className="fa fa-exclamation-circle" /> Validation errors</div>}
                            {!this.props.form.isSaved && <div className="form-notice text-warning"><i className="fa fa-exclamation-triangle" /> Unsaved changes</div>}
                        </div>
                        <div className="col-auto">
                            <button type="submit" disabled={!this.props.form.isValid || this.props.form.isSaved || this.props.saving} className="btn btn-primary">
                                {this.props.saving ? <span className="spinner-border spinner-border-sm" /> : <i className="fa fa-check" />} Save
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }

}