import React from 'react';
import { TagsModel, TagsModelStruct } from '@App/Service/Tags/Model';
import { observer } from 'mobx-react';
import { DetailsFormStore } from '@App/Service/Tags/Details/DetailsFormStore';
import { MainLayout } from '@App/Layout';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { Form } from '@Framework/Library/Form';
import { InputField, TextField } from '@Framework/Component/FormField';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    type : 'groups' | 'locations'
    id : number,
}
interface IState {
    status : EStatus,
    data : TagsModelStruct.ITag,
    saving : boolean,
    deleting : boolean,
    deleteConfirmation : boolean,
}

@observer
export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : DetailsFormStore = new DetailsFormStore();

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: null,
            saving: false,
            deleting: false,
            deleteConfirmation: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.type != prevProps.type ||
            this.props.id != prevProps.id
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        const name = this.props.type == 'groups' ? 'Groups' : 'Locations';
        return (
            <MainLayout service={this.props.type}>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item"><Link url={`/${this.props.type}`}><a>{name}</a></Link></li>
                                    <li className="breadcrumb-item active">
                                        {this.state.status == EStatus.Loaded
                                            ? this.state.data.name
                                            : <><span className="spinner-border spinner-border-sm text-primary" /> Details</>
                                        }
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header">
                                    {this.state.status == EStatus.Loaded
                                        ? <h3 className="m-0">{this.state.data.name}</h3>
                                        : <><span className="spinner-border spinner-border-sm text-primary" /> Details</>
                                    }
                                </div>
                                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.name} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Notes</label>
                                                    <TextField disabled={this.state.saving} store={this.form.fields.notes} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <button type="button" disabled={this.state.deleting || this.state.deleteConfirmation} className="btn btn-danger" onClick={() => this.setState({ deleteConfirmation: true })}>
                                                    {this.state.deleting ? <span className="spinner-border spinner-border-sm" /> : <i className="fa fa-trash" />} Delete
                                                </button>
                                            </div>
                                            <div className="col" />
                                            <div className="col-auto">
                                                {!this.form.isValid && <div className="form-notice text-danger"><i className="fa fa-exclamation-circle" /> Validation errors</div>}
                                                {!this.form.isSaved && <div className="form-notice text-warning"><i className="fa fa-exclamation-triangle" /> Unsaved changes</div>}
                                            </div>
                                            <div className="col-auto">
                                                <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                                    {this.state.saving
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-check" />
                                                    } Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.deleteConfirmation &&
                    <Modal
                        size={EModalSize.SM}
                        onClickOutside={() => this.setState({ deleteConfirmation: false })}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Delete</h5>
                            <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ deleteConfirmation: false })}>
                                <i className="fa fa-times"/>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ deleteConfirmation: false })}><i className="fa fa-ban" /> Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.onDelete()}><i className="fa fa-trash" /> Delete</button>
                        </div>
                    </Modal>
                }
            </MainLayout>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await TagsModel.details(this.props.type, this.props.id);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                });
                this.form.fill(res.payload.data).save();
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        (async () => {
            this.setState({ saving: true });
            const res = await TagsModel.update(this.props.type, this.props.id, this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    message: 'Successfully saved.',
                });
                App.redirect(`/${this.props.type}`);
            } else this.setState({ saving: false });
        })();
    }

    private onDelete() : void {
        (async () => {
            this.setState({ deleteConfirmation: false, deleting: true });
            const res = await TagsModel.delete(this.props.type, this.props.id);
            if(!this.isMount) return;
            if(res.success) {
                App.notification({
                    type: ENotificationType.Success,
                    message: 'Successfully deleted.',
                });
                App.redirect(`/${this.props.type}`);
            }
            this.setState({ deleting: false });
        })();
    }

}