import { EventsModelStruct } from '@App/Service/Events/Model/EventsModel.struct';
import { DefaultStruct } from '@App/Structures';

export namespace EventsModelRes {

    export namespace List {
        export interface IPayload {
            data : EventsModelStruct.IEvent[],
            pagination : DefaultStruct.IPagination,
        }
        interface IPayloadSource {
            data : EventsModelStruct.IEventSource[],
            paginator : DefaultStruct.IPaginationSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    id: Number(item.id) || 0,
                    sensor: {
                        idx: Number(item.sensor.idx) || 0,
                        name: item.sensor.name || item.sensor.idx,
                    },
                    hits: Number(item.hits) || 0,
                    isResolved: item.status == '1',
                    tm: Number(item.tm) || null,
                    tmAdd: Number(item.tm_add) || null,
                    tmRec: Number(item.tm_rec) || null,
                    message: item.txt || '',
                    typeId: Number(item.type_id) || 0,
                    value: item.value != '' ? Number(item.value) || 0 : null,
                    valueTypeId: Number(item.value_type) || 0,
                    valueType: DefaultStruct.Sensors.getValueType(Number(item.value_type)) || null,
                })),
                pagination: DefaultStruct.getPagination(source.paginator),
            };
        }
    }

}