export namespace TagsModelReq {

    export namespace Details {
        export interface IPayload {
            name : string,
            notes : string,
        }
        export interface IPayloadOutput {
            name : string,
            notes : string,
        }
        export function getPayload(source : IPayload) : IPayloadOutput {
            return {
                name: source.name || '',
                notes: source.notes || '',
            };
        }
    }

}