import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { SensorTriggersReq, SensorTriggersRes } from '@App/Service/Sensors/Details/Triggers/Model';

export abstract class SensorTriggersModel extends Model {

    public static list(
        sensorId : number,
        query : SensorTriggersReq.List.IQuery,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorTriggersRes.List.IPayload>> {
        return this.request<SensorTriggersReq.List.IQueryOutput, {}, SensorTriggersRes.List.IPayload>({
            path: `sensors/${sensorId}/triggers`,
            query,
            responseHandler: SensorTriggersRes.List.getPayload,
        }, options);
    }

    public static details(
        sensorId : number,
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorTriggersRes.Details.IPayload>> {
        return this.request<{}, {}, SensorTriggersRes.Details.IPayload>({
            path: `sensors/${sensorId}/triggers/${id}`,
            responseHandler: SensorTriggersRes.Details.getPayload,
        }, options);
    }

    public static update(
        sensorId : number,
        id : number,
        payload : SensorTriggersReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorTriggersRes.Details.IPayload>> {
        return this.request<{}, SensorTriggersReq.Details.IPayloadOutput, SensorTriggersRes.Details.IPayload>({
            path: `sensors/${sensorId}/triggers/${id}`,
            method: ERequestMethod.POST,
            payload: SensorTriggersReq.Details.getOutput(payload),
            responseHandler: SensorTriggersRes.Details.getPayload,
        }, options);
    }

    public static create(
        sensorId : number,
        payload : SensorTriggersReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorTriggersRes.Details.IPayload>> {
        return this.request<{}, SensorTriggersReq.Details.IPayloadOutput, SensorTriggersRes.Details.IPayload>({
            path: `sensors/${sensorId}/triggers/add`,
            method: ERequestMethod.POST,
            payload: SensorTriggersReq.Details.getOutput(payload),
            responseHandler: SensorTriggersRes.Details.getPayload,
        }, options);
    }

    public static delete(
        sensorId : number,
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<{}>> {
        return this.request<{}, {}, {}>({
            path: `sensors/${sensorId}/triggers/${id}`,
            method: ERequestMethod.DELETE,
        }, options);
    }

}