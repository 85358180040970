import { DefaultStruct } from '@App/Structures';
import { ERangeType, RangeSelector } from '@App/Component/RangeSelector';

export namespace EventsModelReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {
            dateRange ?: ERangeType,
            dateFrom ?: number,
            dateTo ?: number,
        }
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {
            from ?: number,
            to ?: number,
        }
        export function getQuery(source : IQuery) : IQueryOutput {
            const query : IQueryOutput = {
                ...RangeSelector.getDateQuery(source),
            };
            if(source.page) query.page = Math.abs(source.page) || 1;
            if(source.limit) query.limit = Math.abs(source.limit) || 50;
            if(source.orderBy) query.orderBy = source.orderBy;
            if(source.sort) query.sort = source.sort == 'asc' ? 'asc' : 'desc';
            return query;
        }
    }

}