import { EFieldType, Field, Group } from '@Framework/Library/Form';
import { makeObservable, override } from 'mobx';

type TFields = {
    locationId : Field,
    groupId : Field,
    port : Field,
    alerts : Field,
    priority : Field,
};

interface IValues {
    locationId : number,
    groupId : number,
    port : string,
    alerts : number,
    priority : number,
}

export class FiltersFormStore extends Group<TFields, IValues> {

    @override protected _fields = {
        locationId: new Field({
            type: EFieldType.Number,
        }),
        groupId: new Field({
            type: EFieldType.Number,
        }),
        port: new Field(),
        alerts: new Field({
            type: EFieldType.Number,
        }),
        priority: new Field({
            type: EFieldType.Number,
        }),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}