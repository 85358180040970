import { EFieldType, Field, Group, Rule } from '@Framework/Library/Form';
import { makeObservable } from 'mobx';

type TFields = {
    alertDaily : Field,
    alertEmail : Field,
    alertEmailTime : Field,
    alertSMS : Field,
    alertSMSTime : Field,
    email : Field,
    name : Field,
    phone : Field,
    roleId : Field,
    isActive : Field,
    password : Field,
    passwordConfirm : Field,
}
interface IValues {
    alertDaily : boolean,
    alertEmail : boolean,
    alertEmailTime : number,
    alertSMS : boolean,
    alertSMSTime : number,
    email : string,
    name : string,
    phone : string,
    roleId : number,
    isActive : boolean,
    password : string,
    passwordConfirm : string,
}

export class CreateFormStore extends Group<TFields, IValues> {

    constructor() {
        super(null, true);
        const password = new Field({
            rules: [ Rule.required() ],
        });
        const passwordConfirm = new Field({
            rules: [ Rule.matches({ field: password }) ],
        });
        this._fields = {
            alertDaily: new Field({
                type: EFieldType.Boolean,
            }),
            alertEmail: new Field({
                type: EFieldType.Boolean,
            }),
            alertEmailTime: new Field({
                type: EFieldType.Number,
                rules: [ Rule.required(), Rule.number({ min: 10 }) ],
            }),
            alertSMS: new Field({
                type: EFieldType.Boolean,
            }),
            alertSMSTime: new Field({
                type: EFieldType.Number,
                rules: [ Rule.required(), Rule.number({ min: 10 }) ],
            }),
            email: new Field({
                rules: [ Rule.required(), Rule.email() ],
            }),
            name: new Field({
                rules: [ Rule.required() ],
            }),
            phone: new Field(),
            roleId: new Field({
                type: EFieldType.Number,
            }),
            isActive: new Field({
                type: EFieldType.Boolean,
            }),
            password,
            passwordConfirm,
        };
        makeObservable(this);
    }

}