import React from 'react';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { SensorsModel, SensorsModelStruct } from '@App/Service/Sensors/Model';
import { Session } from '@Framework/Core/Session';

export enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
export enum ETab {
    Stats,
    Events,
    Settings,
    Triggers,
}
interface IProps {
    children : React.ReactNode,
    id : number,
    tab : ETab,
    controls ?: React.ReactNode,
    onLoad ?: (data : SensorsModelStruct.ISensor) => void,
    onUpdate ?: (Function) => void,
}
interface IState {
    status : EStatus,
    data : SensorsModelStruct.ISensor,
}

export const SensorContext = React.createContext<IState>(null);

export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        if(this.props.onUpdate) this.props.onUpdate((data : SensorsModelStruct.ISensor) => this.setState({ data }));
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="sensors">
                <SensorContext.Provider value={this.state}>
                    <div className="container-fluid my-3">
                        <div className="row">
                            <div className="col">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                        <li className="breadcrumb-item"><Link url="/sensors"><a>Sensors</a></Link></li>
                                        <li className="breadcrumb-item active">
                                            {this.state.status == EStatus.Loaded
                                                ? this.state.data.name || this.state.data.idx
                                                : <><span className="spinner-border spinner-border-sm text-primary" /> Details</>
                                            }
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid my-3">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-header">
                                        {this.state.status == EStatus.Loaded
                                            ? <div className="row">
                                                <div className="col-md">
                                                    <h5 className="m-0">{this.state.data.iconId > 0 && <img src={this.state.data.icon.src} height={22} alt="" />} {this.state.data.name || this.state.data.idx}</h5>
                                                </div>
                                                <div className="col-md-auto">
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            {this.state.data.value !== null &&
                                                                <span className={`badge bg-${this.state.data.alertStatus == 3 ? 'danger' : this.state.data.alertStatus == 2 ? 'warning' : this.state.data.alertStatus == 1 ? 'info' : 'success'}`}>
                                                                    {this.state.data.value}
                                                                    {this.state.data.valueType.sign}
                                                                </span>
                                                            }
                                                        </div>
                                                        {Session.user.role.id == 1 &&
                                                            <div className="col-auto">
                                                                <span className="badge bg-light">Port: {this.state.data.port}</span>
                                                            </div>
                                                        }
                                                        <div className="col px-0" />
                                                        <div className="col-auto">
                                                            <span className={`badge bg-${this.state.data.alerts > 0 ? 'danger' : 'info'}`}>Alerts: {this.state.data.alerts}</span>
                                                        </div>
                                                        <div className="col-auto">
                                                            <span className={`badge bg-${this.state.data.isActive ? 'success' : 'danger'}`}>
                                                                {this.state.data.isActive ? 'Active' : 'Not Active'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <><span className="spinner-border spinner-border-sm"></span> Loading...</>
                                        }
                                    </div>
                                    <div className="card-header p-0 pt-2 border-bottom-0">
                                        <ul className="nav nav-tabs flex-row px-3">
                                            <li className="nav-item">
                                                <Link url={`/sensors/${this.props.id}`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Stats ? 'active' : ''}`}>Stats</a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link url={`/sensors/${this.props.id}/events`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Events ? 'active' : ''}`}>Events</a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link url={`/sensors/${this.props.id}/settings`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Settings ? 'active' : ''}`}>Settings</a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link url={`/sensors/${this.props.id}/triggers`}>
                                                    <a className={`nav-link ${this.props.tab == ETab.Triggers ? 'active' : ''}`}>Triggers</a>
                                                </Link>
                                            </li>
                                            {this.props.controls &&
                                                <li className="ms-auto">
                                                    {this.props.controls}
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </SensorContext.Provider>
            </MainLayout>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await SensorsModel.details(this.props.id);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                });
                if(this.props.onLoad) this.props.onLoad(res.payload.data);
            } else this.setState({ status: EStatus.Failed });
        })();
    }

}