import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { UserModelRes, UserModelReq } from '@App/Service/Users/Model';
import { ERemoteResource } from '@App';
import { DefaultStruct } from '@App/Structures';

export abstract class UserModel extends Model {

    public static list(
        query : DefaultStruct.IQuery = {},
        options : IModelOptions = {},
    ) : Promise<Reply<UserModelRes.List.IPayload>> {
        return this.request<DefaultStruct.IQuery, {}, UserModelRes.List.IPayload>({
            resource: ERemoteResource.Auth,
            path: 'users',
            query,
            responseHandler: UserModelRes.List.getPayload,
        }, options);
    }

    public static details(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<UserModelRes.Details.IPayload>> {
        return this.request<{}, {}, UserModelRes.Details.IPayload>({
            resource: ERemoteResource.Auth,
            path: `users/${id}`,
            responseHandler: UserModelRes.Details.getPayload,
        }, options);
    }

    public static update(
        id : number,
        payload : UserModelReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<UserModelRes.Details.IPayload>> {
        return this.request<{}, UserModelReq.Details.IPayloadOutput, UserModelRes.Details.IPayload>({
            resource: ERemoteResource.Auth,
            path: `users/${id}`,
            method: ERequestMethod.POST,
            payload: UserModelReq.Details.getOutput(payload),
        }, options);
    }

    public static create(
        payload : UserModelReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<UserModelRes.Details.IPayload>> {
        return this.request<{}, UserModelReq.Details.IPayloadOutput, UserModelRes.Details.IPayload>({
            resource: ERemoteResource.Auth,
            path: 'users/add',
            method: ERequestMethod.POST,
            payload: UserModelReq.Details.getOutput(payload),
        }, options);
    }

    public static delete(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, {}, {}>({
            resource: ERemoteResource.Auth,
            path: `users/${id}`,
            method: ERequestMethod.DELETE,
        }, options);
    }

}