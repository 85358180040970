import React from 'react';
import { SensorsModelStruct } from '@App/Service/Sensors/Model';
import moment from 'moment/moment';
import { Format } from '@App';

interface IProps {
    events : SensorsModelStruct.IEvent[],
}
interface IState {}

export class Events extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        return (
            <>
                <div className="card-body p-0 d-none d-md-block">
                    <div className="table-responsive">
                        <table className="table table-hover table-wide m-0">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Recovered</th>
                                    <th>Message</th>
                                    <th>Value</th>
                                    <th>Hits</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.events.map(item => (
                                    <tr key={item.id} className={!item.isResolved ? 'bg-danger' : undefined}>
                                        <td>{moment.unix(item.tmAdd || item.tm).format(`${Format.Date.YN} ${Format.Time.S}`)}</td>
                                        <td>{(item.isResolved && item.tmRec) && moment.unix(item.tmRec).format(`${Format.Date.YN} ${Format.Time.S}`)}</td>
                                        <td>{item.message}</td>
                                        <td>{item.value && `${item.value}${item.valueType.sign}`}</td>
                                        <td>{item.hits}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-body p-0 d-md-none">
                    {this.props.events.map(item => (
                        <div key={item.id} className="item-block">
                            <div className="item-body">
                                <div className="row mb-1">
                                    <div className="col">
                                        <span className="badge bg-secondary">Created<br/>{moment.unix(item.tmAdd || item.tm).format(`${Format.Date.YN} ${Format.Time.S}`)}</span>
                                    </div>
                                    <div className="col text-end">
                                        {(item.isResolved && item.tmRec)
                                            ? <span className="badge bg-danger">Not recovered</span>
                                            : <span className="badge bg-success">Recovered<br/>{moment.unix(item.tmRec).format(`${Format.Date.YN} ${Format.Time.S}`)}</span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <span className="prop">Value:</span>
                                        {' '}
                                        {item.value && `${item.value}${item.valueType.sign}`}
                                    </div>
                                    <div className="col text-end">
                                        <span className="prop">Hits:</span>
                                        {' '}
                                        {item.hits}
                                    </div>
                                </div>
                                {item.message &&
                                    <div className="row">
                                        <div className="col">
                                            <div className="prop">Message:</div>
                                            {item.message}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }

}