import { Field, Group, Rule } from '@Framework/Library/Form';
import { makeObservable, override } from 'mobx';

type TFields = {
    name : Field,
    notes : Field,
};
interface IValues {
    name : string,
    notes : string,
}

export class CreateFormStore extends Group<TFields, IValues> {

    @override protected _fields = {
        name: new Field({
            rules: [ Rule.required() ],
        }),
        notes: new Field(),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}