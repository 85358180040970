import React from 'react';
import { SensorsModelStruct } from '@App/Service/Sensors/Model';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { ERangeType } from '@App/Component/RangeSelector';
import { Format } from '@App';
import { SensorTriggersStruct } from '@App/Service/Sensors/Details/Triggers/Model';

interface IProps {
    rangeType : ERangeType,
    dateFrom : number,
    dateTo : number,
    data : SensorsModelStruct.IStats,
    meta : SensorsModelStruct.IStatsMeta,
    triggers : SensorTriggersStruct.ITrigger[],
    hiddenTriggers : number[],
}
interface IState {}

export class Chart extends React.Component<IProps, IState> {

    public render() : React.ReactNode {
        const annotations : any = {};
        // if(this.props.meta.alertMin) {
        //     annotations.alertMin = {
        //         drawTime: 'afterDatasetsDraw',
        //         type: 'line',
        //         yMin: this.props.meta.alertMin,
        //         yMax: this.props.meta.alertMin,
        //         borderColor: '#ff3366',
        //         borderWidth: 1,
        //         borderDash: [ 10, 10 ],
        //         label: {
        //             content: `Min: ${this.props.meta.alertMin}`,
        //             drawTime: 'afterDatasetsDraw',
        //             enabled: true,
        //             font: {
        //                 size: 10,
        //             },
        //             color: '#2f2f2f',
        //             backgroundColor: 'rgba(255,51,102,0.7)',
        //             xPadding: 5,
        //             yPadding: 3,
        //         },
        //     };
        // }
        // if(this.props.meta.alertMax) {
        //     annotations.alertMax = {
        //         drawTime: 'afterDatasetsDraw',
        //         type: 'line',
        //         yMin: this.props.meta.alertMax,
        //         yMax: this.props.meta.alertMax,
        //         borderColor: '#ff3366',
        //         borderWidth: 1,
        //         borderDash: [ 10, 10 ],
        //         label: {
        //             content: `Max: ${this.props.meta.alertMax}`,
        //             drawTime: 'afterDatasetsDraw',
        //             enabled: true,
        //             font: {
        //                 size: 10,
        //             },
        //             color: '#2f2f2f',
        //             backgroundColor: 'rgba(255,51,102,0.7)',
        //             xPadding: 5,
        //             yPadding: 3,
        //         },
        //     };
        // }
        for(let i = 0; i < this.props.triggers.length; i++) {
            const trigger = this.props.triggers[i];
            if(!trigger.simpleValue) continue;
            if([ 1, 2, 3, 4, 5, 6 ].indexOf(trigger.typeId) < 0) continue;
            if(this.props.hiddenTriggers.indexOf(trigger.id) >= 0) continue;
            annotations[`trigger${trigger.id}`] = {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                yMin: trigger.valueNum,
                yMax: trigger.valueNum,
                borderColor: trigger.level == 3 ? '#ff3366' : trigger.level == 2 ? '#ffcf33' : '#33ffee',
                borderWidth: 1,
                borderDash: [ 10, 10 ],
                label: {
                    content: `${trigger.name}: ${trigger.valueNum}`,
                    drawTime: 'afterDatasetsDraw',
                    enabled: true,
                    font: {
                        size: 10,
                    },
                    color: '#2f2f2f',
                    backgroundColor: trigger.level == 3 ? 'rgba(255,51,102,0.7)' : trigger.level == 2 ? 'rgba(255,207,51,0.7)' : 'rgba(51,255,238,0.7)',
                    xPadding: 5,
                    yPadding: 3,
                },
            };
        }
        return (
            <Line
                height={460}
                options={{
                    elements: {
                        line: {
                            tension: 0.6,
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        tooltip: {
                            enabled: true,
                            mode: 'index',
                            callbacks: {
                                title: items => moment.unix(this.props.data.label[items[0].dataIndex]).format(`${Format.Date.YN} ${Format.Time.M}`),
                                label: item => `${item.dataset.label}: ${item.formattedValue}${this.props.meta.valueType.sign}`,
                            },
                        },
                        annotation: { annotations },
                    },
                }}
                data={{
                    labels: this.props.data.label.map(item => {
                        const oldest = this.props.dateFrom;
                        const newest = this.props.rangeType == ERangeType.Custom && this.props.dateTo
                            ? this.props.dateTo
                            : moment().unix();
                        const date = moment.unix(item);
                        if(oldest >= newest - 129600) {
                            return date.format(Format.Time.M);
                        } else if(oldest >= newest - 60480000) {
                            return date.format(`${Format.Date.MS} ${Format.Time.M}`);
                        } else return date.format(`${Format.Date.YN} ${Format.Time.M}`);
                    }),
                    // datasets: [{
                    //     label: 'Latest',
                    //     data: this.props.data.value,
                    //     borderColor: '#6571ff',
                    //     backgroundColor: 'rgba(101,113,255,0.7)',
                    // }, {
                    //     label: 'Min',
                    //     data: this.props.data.valueMin,
                    //     borderColor: '#66d1d1',
                    //     backgroundColor: 'rgba(102,209,209,0.7)',
                    // }, {
                    //     label: 'Max',
                    //     data: this.props.data.valueMax,
                    //     borderColor: '#ff3366',
                    //     backgroundColor: 'rgba(255,51,102,0.7)',
                    // }],
                    datasets: this.props.meta.dataNames.map(item => ({
                        label: item.name,
                        data: this.props.data.values[item.index],
                        borderColor: item.color.hex,
                        backgroundColor: `rgba(${item.color.rgb.join(',')},0.7)`,
                    })),
                }}
            />
        );
    }

}