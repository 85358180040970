export namespace UserModelReq {

    export namespace Details {
        export interface IPayload {
            alertDaily : boolean,
            alertEmail : boolean,
            alertEmailTime : number,
            alertSMS : boolean,
            alertSMSTime : number,
            email : string,
            name : string,
            phone : string,
            roleId : number,
            isActive : boolean,
            password : string,
            passwordConfirm : string,
        }
        export interface IPayloadOutput {
            alert_daily : string,
            alert_email : string,
            alert_email_time : string,
            alert_sms : string,
            alert_sms_time : string,
            email : string,
            fullname : string,
            phone : string,
            role : string,
            status : string,
            password : string,
            password_confirm : string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            return {
                alert_daily: payload.alertDaily ? '1' : '0',
                alert_email: payload.alertEmail ? '1' : '0',
                alert_email_time: String(payload.alertEmailTime) || '',
                alert_sms: payload.alertSMS ? '1' : '0',
                alert_sms_time: String(payload.alertSMSTime) || '',
                email: payload.email || '',
                fullname: payload.name || '',
                phone: payload.phone || '',
                role: String(payload.roleId) || '',
                status: payload.isActive ? '1' : '0',
                password: payload.password || '',
                password_confirm: payload.passwordConfirm || '',
            };
        }
    }

}