import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { TagsModelReq, TagsModelRes } from '@App/Service/Tags/Model';
import { DefaultStruct } from '@App/Structures';

export abstract class TagsModel extends Model {

    public static list(
        type : 'groups' | 'locations',
        query : DefaultStruct.IQuery,
        options : IModelOptions = {},
    ) : Promise<Reply<TagsModelRes.List.IPayload>> {
        return this.request<DefaultStruct.IQueryOutput, {}, TagsModelRes.List.IPayload>({
            path: type,
            query: DefaultStruct.getQuery(query),
            responseHandler: TagsModelRes.List.getPayload,
        }, options);
    }

    public static details(
        type : 'groups' | 'locations',
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<TagsModelRes.Details.IPayload>> {
        return this.request<{}, {}, TagsModelRes.Details.IPayload>({
            path: `${type}/${id}`,
            responseHandler: TagsModelRes.Details.getPayload,
        }, options);
    }

    // eslint-disable-next-line max-params
    public static update(
        type : 'groups' | 'locations',
        id : number,
        payload : TagsModelReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<TagsModelRes.Details.IPayload>> {
        return this.request<{}, TagsModelReq.Details.IPayloadOutput, TagsModelRes.Details.IPayload>({
            path: `${type}/${id}`,
            method: ERequestMethod.POST,
            payload: TagsModelReq.Details.getPayload(payload),
            responseHandler: TagsModelRes.Details.getPayload,
        }, options);
    }

    public static create(
        type : 'groups' | 'locations',
        payload : TagsModelReq.Details.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<TagsModelRes.Create.IPayload>> {
        return this.request<{}, TagsModelReq.Details.IPayloadOutput, TagsModelRes.Create.IPayload>({
            path: `${type}/add`,
            method: ERequestMethod.POST,
            payload: TagsModelReq.Details.getPayload(payload),
            responseHandler: TagsModelRes.Create.getPayload,
        }, options);
    }

    public static delete(
        type : 'groups' | 'locations',
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, {}, {}>({
            path: `${type}/${id}`,
            method: ERequestMethod.DELETE,
        }, options);
    }

}