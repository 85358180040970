export enum EResponseType {
    Json = 'json',
    Text = 'text',
    Blob = 'blob',
    ArrayBuffer = 'arrayBuffer',
    FormData = 'formData',
}

export type TResponseHandler<TPayload> = (res) => TPayload;

interface IErrorResponse {
    message ?: string,
    errors ?: Record<string, string[]>,
}

interface IProps<TPayload> {
    responseType ?: EResponseType,
    responseHandler ?: TResponseHandler<TPayload>;
    useCustomStatus ?: boolean,
}

interface IReply {
    success : boolean,
    status : number,
    payload ?: any,
    failure ?: IErrorResponse,
}

export class Reply<TPayload = any> {

    private readonly _responseType : EResponseType;
    private readonly _responseHandler : (res : any) => TPayload;
    private readonly _useCustomStatus : boolean;
    private _success : boolean;
    private _status : number;
    private _payload : TPayload;
    private _failure : IErrorResponse;

    public get success() : boolean {
        return this._success;
    }

    public get status() : number {
        return this._status;
    }

    public get payload() : TPayload {
        return this._payload;
    }

    public get failure() : IErrorResponse {
        return this._failure;
    }

    constructor(props : IProps<TPayload>) {
        this._responseType = props.responseType || EResponseType.Json;
        this._responseHandler = props.responseHandler || null;
        this._useCustomStatus = !!props.useCustomStatus;
    }

    public write(data : IReply) : Promise<void>;

    public write(data : Response) : Promise<void>;

    public write(data : any) : Promise<void> {
        this.reset();
        return new Promise<void>(resolve => {
            if(!data) {
                this._success = false;
                this._status = 0;
                resolve();
            } else if(data instanceof Response) {
                data[this._responseType]()
                    .then(payload => {
                        this._success = data.status >= 200 && data.status < 300;
                        this._status = data.status;
                        if(this._useCustomStatus && this._responseType == EResponseType.Json && this._success) {
                            const status = Number(payload.status) || 0;
                            this._success = status >= 200 && status < 300;
                            this._status = status;
                            delete payload.status;
                        }
                        if(this._success) {
                            try {
                                this._payload = this._responseHandler ? this._responseHandler(payload) : payload;
                            } catch {
                                this._success = false;
                            }
                        } else {
                            this._failure = {};
                            if(this._responseType == EResponseType.Json && typeof payload == 'object') {
                                if(payload.message) this._failure.message = payload.message;
                                if(payload.errors) this._failure.errors = payload.errors;
                            }
                        }
                        resolve();
                    })
                    .catch(() => {
                        this._success = false;
                        this._status = data.status || 0;
                        resolve();
                    });
            } else {
                this._success = !!data.success;
                this._status = data.status || 0;
                if(data.payload) this._payload = data.payload;
                if(data.failure) this._failure = data.failure;
                resolve();
            }
        });
    }

    private reset() : void {
        this._success = undefined;
        this._status = undefined;
        this._payload = undefined;
        this._failure = undefined;
    }

}