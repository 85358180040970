import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { SensorsModelReq, SensorsModelRes } from '@App/Service/Sensors/Model';

export abstract class SensorsModel extends Model {

    public static list(
        query : SensorsModelReq.List.IQuery,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.List.IPayload>> {
        return this.request<SensorsModelReq.List.IQueryOutput, {}, SensorsModelRes.List.IPayload>({
            path: 'sensors',
            query: SensorsModelReq.List.getQuery(query),
            responseHandler: SensorsModelRes.List.getPayload,
        }, options);
    }

    public static details(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Details.IPayload>> {
        return this.request<{}, {}, SensorsModelRes.Details.IPayload>({
            path: `sensors/${id}`,
            responseHandler: SensorsModelRes.Details.getPayload,
        }, options);
    }

    public static update(
        id : number,
        payload : SensorsModelReq.Settings.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Details.IPayload>> {
        return this.request<{}, SensorsModelReq.Settings.IPayloadOutput, SensorsModelRes.Details.IPayload>({
            path: `sensors/${id}`,
            method: ERequestMethod.POST,
            payload: SensorsModelReq.Settings.getOutput(payload),
            responseHandler: SensorsModelRes.Details.getPayload,
        }, options);
    }

    public static export(
        sensorId : number,
        payload : SensorsModelReq.Export.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, SensorsModelReq.Export.IPayload, any>({
            path: `sensors/export/${sensorId}`,
            method: ERequestMethod.POST,
            payload,
        }, options);
    }

    public static stats(
        id : number,
        query : any,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Stats.IPayload>> {
        return this.request<SensorsModelReq.Stats.IQuery, {}, SensorsModelRes.Stats.IPayload>({
            path: `sensors/${id}/stats`,
            query: { ...query, minmax: '1' },
            responseHandler: SensorsModelRes.Stats.getPayload,
        }, options);
    }

    public static events(
        id : number,
        query : SensorsModelReq.Events.IQuery,
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Events.IPayload>> {
        return this.request<SensorsModelReq.Events.IQuery, {}, SensorsModelRes.Events.IPayload>({
            path: `sensors/${id}/events`,
            query: SensorsModelReq.Events.getQuery(query),
            responseHandler: SensorsModelRes.Events.getPayload,
        }, options);
    }

    public static groups(
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Relations.IPayload>> {
        return this.relations('groups', options);
    }

    public static locations(
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Relations.IPayload>> {
        return this.relations('locations', options);
    }

    public static relations(
        type : 'groups' | 'locations',
        options : IModelOptions = {},
    ) : Promise<Reply<SensorsModelRes.Relations.IPayload>> {
        return this.request<{}, {}, SensorsModelRes.Relations.IPayload>({
            path: type,
            query: { limit: 500 },
            responseHandler: SensorsModelRes.Relations.getPayload,
        }, options);
    }

}