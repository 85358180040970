import React from 'react';
import { IMiddleware, TOnLoad } from 'subway-router';
import { Session } from '@Framework/Core/Session';
import { Login } from '@App/Service/Auth/Login/Login';
import { TLoadService } from '@Framework/Core/App';

export class LoggedIn implements IMiddleware {

    private readonly _loadService : TLoadService;

    constructor(loadService : TLoadService) {
        this._loadService = loadService;
    }

    public onResolving(onLoad : TOnLoad) : TOnLoad {
        if(Session.isActive) {
            return onLoad;
        } else {
            return () => this._loadService(<Login />);
        }
    }

}