import React from 'react';
import { CreateFormStore } from '@App/Service/Sets/Create/CreateFormStore';
import { Form } from '@Framework/Library/Form';
import { DropdownField, InputField, SearchField, SelectField, SwitchField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';
import { Link } from '@Framework/Factory';
import { SetsModel } from '@App/Service/Sets/Model/SetsModel';
import { sensorIcons } from '@App/Assets/SensorIcons';
import { SensorsModel } from '@App/Service/Sensors/Model/SensorsModel';
import { MainLayout } from '@App/Layout';

export enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}

interface IProps {}
interface IState {
    saving : boolean,
    status : EStatus,
    tab : number,
    moveTag : number,
}

@observer
export class Create extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : CreateFormStore = new CreateFormStore();

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            status: EStatus.Unloaded,
            tab: 0,
            moveTag: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.form.save();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="sets">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item"><Link url="/sets"><a>Sets</a></Link></li>
                                    <li className="breadcrumb-item active">Create</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header"><h3 className="m-0">Create</h3></div>
                                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Name of the set <span className="text-danger">*</span></label>
                                                    <InputField disabled={this.state.saving} store={this.form.fields.name} />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Icon <span className="text-danger">*</span></label>
                                                    <DropdownField
                                                        id="iconId"
                                                        disabled={this.state.saving}
                                                        store={this.form.fields.iconId}
                                                        options={Object.keys(sensorIcons).map((item, index) => ({
                                                            value: String(index + 1),
                                                            option: <><img src={sensorIcons[item]} height={22} alt=""/></>,
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col">
                                                <div className="row mb-2">
                                                    <div className="col"><p>Set Sensors</p></div>
                                                    <div className="col-auto">
                                                        <button type="button" className="btn btn-warning btn-sm" onClick={() => this.form.fields.sensors.append()}>
                                                            <i className="fa fa-plus"/> Add
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        {this.form.fields.sensors.items.map((item, index) => (
                                                            <div key={item.id} className="card">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-auto">
                                                                            <div className="btn-group">
                                                                                <button type="button" className="btn btn-secondary" disabled={this.form.fields.sensors.items.length < 2 || index < 1} onClick={() => this.form.fields.sensors.swap(index, index - 1)}>
                                                                                    <i className="fa fa-chevron-up" />
                                                                                </button>
                                                                                <button type="button" className="btn btn-secondary" disabled={this.form.fields.sensors.items.length < 2 || index >= this.form.fields.sensors.items.length - 1} onClick={() => this.form.fields.sensors.swap(index, index + 1)}>
                                                                                    <i className="fa fa-chevron-down" />
                                                                                </button>
                                                                                <button type="button" className={`btn btn-${this.state.moveTag == index ? 'info' : 'secondary'}`} disabled={this.form.fields.sensors.items.length < 2} onClick={() => this.move(index)}>
                                                                                    <i className="fa fa-sort" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col px-0">
                                                                            <div className="form-group">
                                                                                <SearchField
                                                                                    id={'sensors-search'}
                                                                                    onSearch={async (keywords, page) => {
                                                                                        const res = await SensorsModel.list({
                                                                                            qstr: keywords,
                                                                                            page,
                                                                                            limit: 10,
                                                                                        });
                                                                                        return {
                                                                                            keywords,
                                                                                            options: res.success && res.payload
                                                                                                ? res.payload.data.map(item => ({
                                                                                                    id: item.idx,
                                                                                                    name: item.name,
                                                                                                }))
                                                                                                : [],
                                                                                            pages: res.success && res.payload
                                                                                                ? res.payload.pagination.page
                                                                                                : 1,
                                                                                            total: res.success && res.payload
                                                                                                ? res.payload.pagination.pages
                                                                                                : 1,
                                                                                        };
                                                                                    }}
                                                                                    store={item}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <button type="button" className="btn btn-danger" onClick={() => this.form.fields.sensors.remove(index)}>
                                                                                <i className="fa fa-times"/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row align-items-center">
                                            <div className="col" />
                                            <div className="col-auto">
                                                {!this.form.isValid && <div className="form-notice text-danger"><i className="fa fa-exclamation-circle" /> Validation errors</div>}
                                                {!this.form.isSaved && <div className="form-notice text-warning"><i className="fa fa-exclamation-triangle" /> Unsaved changes</div>}
                                            </div>
                                            <div className="col-auto">
                                                <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                                    {this.state.saving
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-check" />
                                                    } Create
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    public move(index) : void {
        if(this.state.moveTag == null) {
            this.setState({ moveTag: index });
        } else if(this.state.moveTag == index) {
            this.setState({ moveTag: null });
        } else {
            this.form.fields.sensors.swap(this.state.moveTag, index);
            this.setState({ moveTag: null });
        }
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ saving: true });
        (async () => {
            const res = await SetsModel.create(this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully created.',
                });
                App.redirect(`/sets/${res.payload.data.id}`);
            }
            this.setState({ saving: true });
        })();
    }

}