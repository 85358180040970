import { ERequestMethod, IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { SetsModelRes } from '@App/Service/Sets/Model/SetsModel.res';
import { SetsModelReq } from '@App/Service/Sets/Model/SetsModel.req';

export abstract class SetsModel extends Model {

    public static list(
        query : SetsModelReq.List.IQuery,
        options : IModelOptions = {},
    ) : Promise<Reply<SetsModelRes.List.IPayload>> {
        return this.request<SetsModelReq.List.IQueryOutput, {}, SetsModelRes.List.IPayload>({
            path: 'sets',
            query: SetsModelReq.List.getQuery(query),
            responseHandler: SetsModelRes.List.getPayload,
        }, options);
    }

    public static create(
        payload : SetsModelReq.Create.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, SetsModelReq.Create.IPayloadOutput, any>({
            path: 'sets/create',
            method: ERequestMethod.POST,
            payload: SetsModelReq.Create.getOutput(payload),
        }, options);
    }

    public static details(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<SetsModelRes.Details.IPayload>> {
        return this.request<{}, {}, SetsModelRes.Details.IPayload>({
            method: ERequestMethod.GET,
            path: `sets/${id}`,
            responseHandler: SetsModelRes.Details.getPayload,
        }, options);
    }

    public static update(
        id : number,
        payload : SetsModelReq.Update.IPayload,
        options : IModelOptions = {},
    ) : Promise<Reply<SetsModelRes.Details.IPayload>> {
        return this.request<{}, SetsModelReq.Update.IPayloadOutput, SetsModelRes.Details.IPayload>({
            path: `sets/${id}`,
            method: ERequestMethod.POST,
            payload: SetsModelReq.Update.getOutput(payload),
            responseHandler: SetsModelRes.Details.getPayload,
        }, options);
    }

    public static delete(
        id : number,
        options : IModelOptions = {},
    ) : Promise<Reply<any>> {
        return this.request<{}, {}, {}>({
            path: `sets/${id}`,
            method: ERequestMethod.DELETE,
        }, options);
    }

}