import React from 'react';
import { Link } from '@Framework/Factory';
import { Session } from '@Framework/Core/Session';
import '@App/Layout/MainLayout/MainMenu/MainMenu.less';

interface IProps {
    active : string,
}
interface IState {}

export class MainMenu extends React.Component<IProps, IState> {

    public render() {
        return (
            <div id="main-menu">
                <ul>
                    <li className={this.props.active == 'sensors' ? 'active' : ''}>
                        <Link url={'/sensors'}>
                            <a><span><i className="fa fa-mobile-phone" /></span> <span className="d-none d-md-inline">Sensors</span></a>
                        </Link>
                    </li>
                    <li className={this.props.active == 'sets' ? 'active' : ''}>
                        <Link url={'/sets'}>
                            <a><span><i className="fa fa-object-group" /></span> <span className="d-none d-md-inline">Sets</span></a>
                        </Link>
                    </li>
                    <li className={this.props.active == 'events' ? 'active' : ''}>
                        <Link url={'/events'}>
                            <a><span><i className="fa fa-calendar-o" /></span> <span className="d-none d-md-inline">Events</span></a>
                        </Link>
                    </li>
                    <li className={this.props.active == 'groups' ? 'active' : ''}>
                        <Link url={'/groups'}>
                            <a><span><i className="fa fa-object-ungroup" /></span> <span className="d-none d-md-inline">Groups</span></a>
                        </Link>
                    </li>
                    <li className={this.props.active == 'locations' ? 'active' : ''}>
                        <Link url={'/locations'}>
                            <a><span><i className="fa fa-map-marker" /></span> <span className="d-none d-md-inline">Locations</span></a>
                        </Link>
                    </li>
                    {Session.user.role.id < 3 &&
                        <li className={this.props.active == 'users' ? 'active' : ''}>
                            <Link url={'/users'}>
                                <a><span><i className="fa fa-users" /></span> <span className="d-none d-md-inline">Users</span></a>
                            </Link>
                        </li>
                    }
                </ul>
            </div>
        );
    }

}