import { DefaultStruct } from '@App/Structures';

export namespace SensorsModelStruct {

    export const sensorPriorities : DefaultStruct.IOption[] = [
        { id: 1, name: 'Highest' },
        { id: 2, name: 'High' },
        { id: 3, name: 'Normal' },
        { id: 4, name: 'Low' },
        { id: 5, name: 'Lowest' },
    ];

    export interface ISensor {
        isActive : boolean,
        alertEmail : boolean,
        alertEmailTime : number,
        alertMax : number,
        alertMin : number,
        alertSMS : boolean,
        alertSMSTime : number,
        alertStatus : number,
        alerts : number,
        companyId : number,
        dataEnd : number,
        dataHistStep : number,
        dataMinStep : number,
        dataStart : number,
        isDeleted : boolean,
        deviceId : number,
        groupId : number,
        group : DefaultStruct.IOption,
        iconId : number,
        icon : {
            id : number,
            src : string,
        },
        idx : number,
        locationId : number,
        location : DefaultStruct.IOption,
        name : string,
        notes : string,
        port : string,
        priorityId : number,
        priority : DefaultStruct.IOption,
        tm : number,
        tmA : number,
        value : number,
        valueCor : number,
        valueTypeId : number,
        valueType : DefaultStruct.Sensors.IValueType,
    }
    export interface ISensorSource {
        active : string,
        alert_email : string,
        alert_email_time : string,
        alert_max : string,
        alert_min : string,
        alert_sms : string,
        alert_sms_time : string,
        alert_status : string,
        alerts : string,
        company_id : string,
        data_end : string,
        data_histstep : string,
        data_minstep : string,
        data_start : string,
        deleted : string,
        device_id : string,
        group_id : string,
        group ?: DefaultStruct.IOption,
        icon_id : string,
        idx : string,
        location_id : string,
        location ?: DefaultStruct.IOption,
        name : string,
        notes : string,
        port : string,
        priority : string,
        tm : string,
        tm_a : string,
        value : string,
        value_cor : string,
        value_type : string,
    }

    export interface IStats {
        label : number[],
        value : number[],
        valueMin : number[],
        valueMax : number[],
        values : number[][],
    }
    export interface IStatsSource {
        label : number[],
        value : string[],
        value_min : string[],
        value_max : string[],
        values : {
            value : string[],
        }[],
    }

    export interface IStatsMeta {
        isActive : boolean,
        alertMax : number,
        alertMin : number,
        alertStatus : number,
        dataEnd : number,
        dataMinStep : number,
        dataNames : {
            index : number,
            name : string,
            color : {
                hex : string,
                rgb : number[],
            },
        }[],
        dataRoundStep : number,
        dataStart : number,
        deviceId : number,
        id : number,
        name : string,
        points : number,
        port : string,
        stepSize : number,
        value : number,
        valueTypeId : number,
        valueType : DefaultStruct.Sensors.IValueType,
    }
    export interface IStatsMetaSource {
        active : string,
        alert_max : string,
        alert_min : string,
        alert_status : string,
        data_end : string,
        data_minstep : string,
        data_names : {
            id : number,
            name : string,
            color : string,
        }[],
        data_roundstep : number,
        data_start : string,
        device_id : string,
        id : string,
        name : string,
        points : number,
        port : string,
        stepsize : number,
        value : string,
        value_type : string,
    }
    export interface IEvent {
        id : number,
        sensor : {
            idx : number,
            name : string,
        },
        hits : number,
        isResolved : boolean,
        tm : number,
        tmAdd : number,
        tmRec : number,
        message : string,
        typeId : number,
        value : number,
        valueTypeId : number,
        valueType : DefaultStruct.Sensors.IValueType,
    }
    export interface IEventSource {
        id : string,
        sensor : {
            idx : string,
            name : string,
        },
        hits : string,
        status : string,
        tm : string,
        tm_add : string,
        tm_rec : string,
        txt : string,
        type_id : string,
        value : string,
        value_type : string,
    }

}