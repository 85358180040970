import { TagsModelStruct } from '@App/Service/Tags/Model/TagsModel.struct';
import { DefaultStruct } from '@App/Structures';

export namespace TagsModelRes {

    export namespace List {
        export interface IPayload {
            data : TagsModelStruct.ITag[],
            pagination : DefaultStruct.IPagination,
        }
        interface IPayloadSource {
            data : TagsModelStruct.ITagSource[],
            paginator : DefaultStruct.IPaginationSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: source.data.map(item => ({
                    id: Number(item.id),
                    name: item.name || '',
                    notes: item.notes || '',
                })),
                pagination: {
                    limit: Number(source.paginator.limit) || 25,
                    page: Number(source.paginator.page) || 1,
                    pages: Number(source.paginator.pages) || 1,
                    total: Number(source.paginator.total) || 0,
                },
            };
        }
    }

    export namespace Details {
        export interface IPayload {
            data : TagsModelStruct.ITag,
        }
        interface IPayloadSource {
            data : TagsModelStruct.ITagSource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: {
                    id: Number(source.data.id) || 0,
                    name: source.data.name || '',
                    notes: source.data.notes || '',
                },
            };
        }
    }

    export namespace Create {
        export interface IPayload {
            data : number,
        }
        interface IPayloadSource {
            data : string,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            return {
                data: Number(source.data),
            };
        }
    }

}