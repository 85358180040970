import { DefaultStruct } from '@App/Structures';

export namespace SensorTriggersReq {

    export namespace List {
        export interface IQuery extends DefaultStruct.IQuery {}
        export interface IQueryOutput extends DefaultStruct.IQueryOutput {}
    }

    export namespace Details {
        export interface IPayload {
            name : string,
            timeLimit : number,
            simpleValue : boolean,
            valueNum : number,
            valueJson : string,
            typeId : number,
            level : number,
            isActive : boolean,
        }
        export interface IPayloadOutput {
            name : string,
            time_limit : string,
            value_num : string,
            value_json : string,
            type : string,
            level : string,
            active : string,
        }
        export function getOutput(payload : IPayload) : IPayloadOutput {
            return {
                name: payload.name || '',
                time_limit: String(payload.timeLimit) || '',
                value_num: payload.simpleValue ? String(payload.valueNum) || '' : null,
                value_json: payload.simpleValue ? null : payload.valueJson || '{}',
                type: String(payload.typeId) || '1',
                level: String(payload.typeId) || '1',
                active: payload.isActive ? '1' : '0',
            };
        }
    }

}