import { EFieldType, Field, Group, Rule } from '@Framework/Library/Form';
import { makeObservable } from 'mobx';

type TFields = {
    name : Field,
    timeLimit : Field,
    simpleValue : Field,
    valueNum : Field,
    valueJson : Field,
    typeId : Field,
    level : Field,
    isActive : Field,
}
interface IValues {
    name : string,
    timeLimit : number,
    simpleValue : boolean,
    valueNum : number,
    valueJson : string,
    typeId : number,
    level : number,
    isActive : boolean,
}

export class TriggerFormStore extends Group<TFields, IValues> {

    constructor() {
        super(null, true);
        const simpleValue = new Field({
            type: EFieldType.Boolean,
        });
        this._fields = {
            name: new Field({
                rules: [ Rule.required() ],
            }),
            timeLimit: new Field({
                type: EFieldType.Number,
                rules: [
                    Rule.required(),
                    Rule.number({ noDecimal: true }),
                ],
            }),
            simpleValue,
            valueNum: new Field({
                type: EFieldType.Number,
                rules: [
                    Rule.if({
                        cond: () => simpleValue.value == '1',
                        rules: [
                            Rule.required(),
                            Rule.number(),
                        ],
                    }),
                ],
            }),
            valueJson: new Field({
                rules: [
                    Rule.if({
                        cond: () => simpleValue.value != '1',
                        rules: [ Rule.required() ],
                    }),
                ],
            }),
            typeId: new Field({
                type: EFieldType.Number,
                rules: [
                    Rule.required(),
                    Rule.number({
                        noDecimal: true,
                        min: 1,
                        max: 10,
                    }),
                ],
            }),
            level: new Field({
                type: EFieldType.Number,
                rules: [
                    Rule.required(),
                    Rule.number({
                        noDecimal: true,
                        min: 1,
                        max: 3,
                    }),
                ],
            }),
            isActive: new Field({
                type: EFieldType.Boolean,
            }),
        };
        makeObservable(this);
    }

}