export namespace UserModelStruct {

    export interface IUserShort {
        company : {
            id : number,
            name : string,
        },
        email : string,
        id : number,
        name : string,
        role : {
            id : number,
            name : string,
        },
        isActive : boolean,
        tm : number,
    }
    export interface IUserShortSource {
        company_id : string,
        company_name : string,
        email : string,
        id : string,
        name : string,
        role : string,
        status : string,
        tm : string,
    }
    export interface IUser extends IUserShort {
        alertDaily : boolean,
        alertEmail : boolean,
        alertEmailTime : number,
        alertSMS : boolean,
        alertSMSTime : number,
        name : string,
        phone : string,
        resetHashTm : number,
        tmA : number,
    }
    export interface IUserSource extends IUserShortSource {
        alert_daily : string,
        alert_email : string,
        alert_email_time : string,
        alert_sms : string,
        alert_sms_time : string,
        fullname : string,
        phone : string,
        resethash_tm : string,
        tm_a : string,
    }

}