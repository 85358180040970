import React from 'react';
import { CreateFormStore } from '@App/Service/Tags/Create/CreateFormStore';
import { InputField, TextField } from '@Framework/Component/FormField';
import { Form } from '@Framework/Library/Form';
import { Modal } from '@Framework/Factory';
import { TagsModel } from '@App/Service/Tags/Model';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';

interface IProps {
    type : 'groups' | 'locations',
    onCreated : Function,
    onClose : Function,
}
interface IState {
    saving : boolean,
}

export class CreateTagModal extends React.Component<IProps, IState> {

    private form : CreateFormStore = new CreateFormStore();

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
        };
    }

    public render() : React.ReactNode {
        const name = this.props.type == 'groups' ? 'Groups' : 'Locations';
        return (
            <Modal
                onClickOutside={() => this.state.saving ? null : this.props.onClose()}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{name}</h5>
                    <button type="button" className="btn btn-light btn-sm" onClick={() => this.props.onClose()}>
                        <i className="fa fa-times"/>
                    </button>
                </div>
                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Name</label>
                                    <InputField disabled={this.state.saving} store={this.form.fields.name} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>Notes</label>
                                    <TextField disabled={this.state.saving} store={this.form.fields.notes} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row align-items-center">
                            <div className="col" />
                            <div className="col-auto">
                                {!this.form.isValid && <div className="form-notice text-danger"><i className="fa fa-exclamation-circle" /> Validation errors</div>}
                            </div>
                            <div className="col-auto">
                                <button type="submit" disabled={this.state.saving} className="btn btn-primary">
                                    {this.state.saving
                                        ? <span className="spinner-border spinner-border-sm" />
                                        : <i className="fa fa-check" />
                                    } Create
                                </button>
                                <button type="button" disabled={this.state.saving} className="btn btn-secondary" onClick={() => this.props.onClose()}>
                                    <i className="fa fa-ban" /> Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        (async () => {
            this.setState({ saving: true });
            const res = await TagsModel.create(this.props.type, this.form.getValues());
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    message: 'Successfully created.',
                });
                this.props.onCreated(res.payload.data);
            } else this.setState({ saving: false });
        })();
    }

}