export namespace AuthModelRes {

    export namespace Login {
        export interface IPayload {
            token : {
                access : string,
                refresh : string,
                expiresAt : number,
            },
            instances : {
                id : number,
                port : number,
            }[],
        }
        interface IPayloadSource {
            data : {
                instances : {
                    id : string,
                    id2 : string,
                    port : string,
                    port2 : string,
                }
                refresh : string,
                token : string,
                tm_e : number,
            },
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            const instances = [
                {
                    id: Number(source.data.instances.id),
                    port: Number(source.data.instances.port) || 443,
                },
                ...source.data.instances.id2
                    ? [{
                        id: Number(source.data.instances.id2),
                        port: Number(source.data.instances.port2) || 443,
                    }]
                    : [],
            ];
            return {
                token: {
                    access: source.data.token,
                    refresh: source.data.refresh,
                    expiresAt: Number(source.data.tm_e),
                },
                instances,
            };
        }
    }

    export namespace User {
        export interface IPayload {
            id : number,
            name : string,
            email : string,
            companyId : number,
            role : {
                id : number,
                name : string,
            },
            companies : {
                id : number,
                name : string,
            }[],
            instances : {
                id : number,
                port : number,
            }[],
        }
        interface IPayloadSource {
            data : {
                alert_email : string,
                alert_email_time : string,
                alert_sms : string,
                alert_sms_time : string,
                companies : {
                    id : string,
                    name : string,
                }[],
                company_id : string,
                email : string,
                fullname : string,
                id : string,
                phone : string,
                role : string,
                instances : {
                    id : string,
                    id2 : string,
                    port : string,
                    port2 : string,
                }
            },
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            const instances = [
                {
                    id: Number(source.data.instances.id),
                    port: Number(source.data.instances.port) || 443,
                },
                ...source.data.instances.id2
                    ? [{
                        id: Number(source.data.instances.id2),
                        port: Number(source.data.instances.port2) || 443,
                    }]
                    : [],
            ];
            return {
                id: Number(source.data.id),
                name: source.data.fullname,
                email: source.data.email,
                companyId: Number(source.data.company_id),
                role: {
                    id: Number(source.data.role),
                    name: source.data.role == '1'
                        ? 'SuperAdmin'
                        : source.data.role == '2'
                            ? 'Admin'
                            : 'User',
                },
                companies: (source.data.companies || []).map(item => ({
                    id: Number(item.id) || 0,
                    name: item.name || '',
                })),
                instances,
            };
        }

    }

    export namespace CompanyDetails {

        export interface ICompany {
            id : number,
            name : string,
            instances : {
                id : number,
                port : number,
            }[],
        }
        export interface ICompanySource {
            id : string,
            name : string,
            users : string,
            instance_id : string,
            address : string,
            notes : string,
            owner_id : string,
            instances : {
                id : string,
                id2 : string,
                port : string,
                port2 : string,
            }
        }
        export interface IPayload {
            data : ICompany,
        }
        export interface IPayloadSource {
            data : ICompanySource,
            status : number,
        }
        export function getPayload(source : IPayloadSource) : IPayload {
            try {
                const instances = [
                    {
                        id: Number(source.data.instances.id),
                        port: Number(source.data.instances.port) || 443,
                    },
                    ...source.data.instances.id2
                        ? [{
                            id: Number(source.data.instances.id2),
                            port: Number(source.data.instances.port2) || 443,
                        }]
                        : [],
                ];
                return {
                    data: {
                        id: Number(source.data.id),
                        name: source.data.name || '',
                        instances,
                    },
                };
            } catch {
                return null;
            }
        }

    }

}