export namespace AuthModelReq {

    export namespace Login {
        export interface IPayload {
            username : string,
            password : string,
        }
        export interface IPayloadOutput {
            email : string,
            pass : string,
        }
        export function getPayload(payload : IPayload) : IPayloadOutput {
            return {
                email: payload.username || '',
                pass: payload.password || '',
            };
        }
    }

    export namespace Token {
        export interface IPayload {
            token : string,
        }
        export interface IPayloadOutput {
            refresh_token : string,
        }
        export function getPayload(payload : IPayload) : IPayloadOutput {
            return {
                refresh_token: payload.token || '',
            };
        }
    }

}