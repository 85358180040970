import React from 'react';
import { SensorTriggersModel, SensorTriggersStruct } from '@App/Service/Sensors/Details/Triggers/Model';
import { Details, ETab } from '@App/Service/Sensors/Details/Details';
import { Loading } from '@Framework/Component/Loading';
import { Pagination } from '@Framework/Component/Pagination';
import { DefaultStruct } from '@App/Structures';
import { Link } from '@Framework/Factory';
import { App } from '@Framework/Core/App';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    sensorId : number,
    page ?: number,
    limit ?: number,
    orderBy ?: string,
    sort ?: string,
}
interface IState {
    status : EStatus,
    data : SensorTriggersStruct.ITrigger[],
    pagination : DefaultStruct.IPagination,
}

export class List extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: [],
            pagination: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.sensorId != prevProps.sensorId ||
            this.props.page != prevProps.page ||
            this.props.limit != prevProps.limit ||
            this.props.orderBy != prevProps.orderBy ||
            this.props.sort != prevProps.sort
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <Details
                id={this.props.sensorId}
                tab={ETab.Triggers}
                controls={<Link url={`/sensors/${this.props.sensorId}/triggers/create`}><a className="btn btn-primary btn-sm"><i className="fa fa-plus" /> Create</a></Link>}
            >
                {this.state.status != EStatus.Loaded &&
                    <div className="card-body">
                        {this.state.status == EStatus.Loading && <Loading label="Loading..." />}
                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error</div>}
                    </div>
                }
                {this.state.status == EStatus.Loaded &&
                    <>
                        <div className="card-body p-0 card-body p-0 d-none d-md-block">
                            <div className="table-responsive">
                                <table className="table table-hover table-wide m-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Level</th>
                                            <th>Type</th>
                                            <th>Value</th>
                                            <th>Active</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map(item => (
                                            <tr key={item.id}>
                                                <td onClick={() => this.opedDetails(item.id)}>{item.name}</td>
                                                <td onClick={() => this.opedDetails(item.id)}>
                                                    {item.level == 1 ? 'Notice' : item.level == 2 ? 'Warning' : 'Fatal'}
                                                </td>
                                                <td onClick={() => this.opedDetails(item.id)}>{item.type ? item.type.name : ''}</td>
                                                <td onClick={() => this.opedDetails(item.id)}>{item.valueNum}</td>
                                                <td onClick={() => this.opedDetails(item.id)}>{item.isActive ? <span className="badge bg-success">Yes</span> : <span className="badge bg-danger">No</span>}</td>
                                                <td className="text-end">
                                                    <Link url={`/sensors/${this.props.sensorId}/triggers/${item.id}`}>
                                                        <a className="btn btn-xs btn-primary"><i className="fa fa-edit" /> Details</a>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-body p-0 d-md-none">
                            {this.state.data.map(item => (
                                <div key={item.id} className="item-block">
                                    <div className="item-body">
                                        <div className="row mb-1">
                                            <div className="col">
                                                <b>{item.name}</b>
                                                <div>
                                                    <span className="prop">Type:</span>
                                                    {' '}
                                                    {item.type ? item.type.name : ''}
                                                </div>
                                                <div>
                                                    <span className="prop">Level:</span>
                                                    {' '}
                                                    {item.level}
                                                </div>
                                            </div>
                                            <div className="col text-end">
                                                <div>{item.isActive ? <span className="badge bg-success">Yes</span> : <span className="badge bg-danger">No</span>}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-controls text-end">
                                        <Link url={`/sensors/${this.props.sensorId}/triggers/${item.id}`}>
                                            <a className="btn btn-xs btn-primary"><i className="fa fa-edit" /> Details</a>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                }
                <div className="card-footer">
                    <Pagination limit={this.props.limit} { ...this.state.pagination } />
                </div>
            </Details>
        );
    }

    private opedDetails(id : number) : void {
        App.redirect(`/sensors/${this.props.sensorId}/triggers/${id}`);
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await SensorTriggersModel.list(this.props.sensorId, this.props);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data || [],
                    pagination: res.payload.pagination || null,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

}