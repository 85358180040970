import React from 'react';
import { UserModel, UserModelStruct } from '@App/Service/Users/Model';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { Loading } from '@Framework/Component/Loading';
import { Pagination } from '@Framework/Component/Pagination';
import { DefaultStruct } from '@App/Structures';
import { App } from '@Framework/Core/App';
import { SortLink } from '@Framework/Component/SortLink';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    page ?: number,
    limit ?: number,
    orderBy ?: string,
    sort ?: string,
}
interface IState {
    status : EStatus,
    data : UserModelStruct.IUserShort[],
    pagination : DefaultStruct.IPagination,
}

export class List extends React.Component<IProps, IState> {

    private isMount : boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: [],
            pagination: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(
            this.props.page != prevProps.page ||
            this.props.limit != prevProps.limit ||
            this.props.orderBy != prevProps.orderBy ||
            this.props.sort != prevProps.sort
        ) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="users">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item active">Users</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col"><h3 className="m-0">Users</h3></div>
                                        <div className="col-auto">
                                            <Link url="/users/create"><a className="btn btn-sm btn-primary"><i className="fa fa-plus" /> Create</a></Link>
                                        </div>
                                    </div>
                                </div>
                                {this.state.status != EStatus.Loaded &&
                                    <div className="card-body">
                                        {this.state.status == EStatus.Loading && <Loading />}
                                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error...</div>}
                                    </div>
                                }
                                {this.state.status == EStatus.Loaded &&
                                    <>
                                        <div className="card-body p-0 d-none d-md-block">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-striped table-wide m-0">
                                                    <thead>
                                                        <tr>
                                                            <th><SortLink name="name">Name</SortLink></th>
                                                            <th><SortLink name="email">Email</SortLink></th>
                                                            <th><SortLink name="role">Role</SortLink></th>
                                                            <th/>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.data.map(item => (
                                                            <tr key={item.id}>
                                                                <td onClick={() => List.openDetails(item.id)}>
                                                                    {item.name}
                                                                </td>
                                                                <td onClick={() => List.openDetails(item.id)}>
                                                                    {item.email}
                                                                </td>
                                                                <td onClick={() => List.openDetails(item.id)}>
                                                                    {item.role.name}
                                                                </td>
                                                                <td className="text-end">
                                                                    <Link url={`/users/${item.id}`}>
                                                                        <a className="btn btn-xs btn-primary">
                                                                            <i className="fa fa-eye" /> Details
                                                                        </a>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <Pagination limit={this.props.limit} { ...this.state.pagination } />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await UserModel.list(this.props);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                    pagination: res.payload.pagination,
                });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private static openDetails(id : number) : void {
        App.redirect(`/users/${id}`);
    }

}