import React from 'react';
import { MainLayout } from '@App/Layout';
import { EModalSize, Link, Modal } from '@Framework/Factory';
import { UserModel, UserModelStruct } from '@App/Service/Users/Model';
import { DetailsFormStore } from '@App/Service/Users/Details/DetailsFormStore';
import { Form } from '@Framework/Library/Form';
import { Loading } from '@Framework/Component/Loading';
import { InputField, SelectField, SwitchField } from '@Framework/Component/FormField';
import { observer } from 'mobx-react';
import { Session } from '@Framework/Core/Session';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
enum EAction {
    Idle,
    Saving,
    DeleteConfirmation,
    Deleting,
}
interface IProps {
    id : number,
}
interface IState {
    status : EStatus,
    action : EAction,
    data : UserModelStruct.IUser,
}

@observer
export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : DetailsFormStore = new DetailsFormStore();

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            action: EAction.Idle,
            data: null,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <MainLayout service="users">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item"><Link url="/users"><a>Users</a></Link></li>
                                    <li className="breadcrumb-item active">
                                        {this.state.status == EStatus.Loaded
                                            ? this.state.data.name
                                            : <><span className="spinner-border spinner-border-sm text-primary" /> Details</>
                                        }
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header">
                                    {this.renderHeader()}
                                </div>
                                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                    <div className="card-body">
                                        {this.state.status == EStatus.Loading && <Loading label="Loading chart..." />}
                                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Stats loading error</div>}
                                        {this.state.status == EStatus.Loaded &&
                                        <>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.name} />
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Alert Daily</label>
                                                        <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.alertDaily} wide={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            {Session.user.role.id == 1 &&
                                                <div className="row">
                                                    <div className="col-sm">
                                                        <div className="form-group">
                                                            <label>Role</label>
                                                            <SelectField disabled={this.state.action != EAction.Idle} store={this.form.fields.roleId}>
                                                                <option value="1">Super Admin</option>
                                                                <option value="2">Admin</option>
                                                                <option value="3">User</option>
                                                            </SelectField>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm">
                                                        <div className="form-group">
                                                            <label>Is active</label>
                                                            <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.isActive} wide={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.email} />
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Phone</label>
                                                        <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.phone} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Alert SMS</label>
                                                        <div className="field-group">
                                                            <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.alertSMS} />
                                                            <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.alertSMSTime} wrapperClass="w-100" />
                                                            <span className="field-group-text">Min</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Alert Email</label>
                                                        <div className="field-group">
                                                            <SwitchField disabled={this.state.action != EAction.Idle} store={this.form.fields.alertEmail} />
                                                            <InputField disabled={this.state.action != EAction.Idle} store={this.form.fields.alertEmailTime} wrapperClass="w-100" />
                                                            <span className="field-group-text">Min</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>New password</label>
                                                        <InputField type="password" disabled={this.state.action != EAction.Idle} store={this.form.fields.password} />
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Confirm password</label>
                                                        <InputField type="password" disabled={this.state.action != EAction.Idle} store={this.form.fields.passwordConfirm} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        }
                                    </div>
                                    {this.state.status == EStatus.Loaded &&
                                        <div className="card-footer">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <button
                                                        type="button"
                                                        disabled={this.state.action != EAction.Idle}
                                                        className="btn btn-danger"
                                                        onClick={() => this.setState({
                                                            action: EAction.DeleteConfirmation,
                                                        })}
                                                    >
                                                        {this.state.action == EAction.Deleting
                                                            ? <span className="spinner-border spinner-border-sm" />
                                                            : <i className="fa fa-trash" />
                                                        } Delete
                                                    </button>
                                                </div>
                                                <div className="col"/>
                                                <div className="col-auto">
                                                    <button type="submit" disabled={this.state.action != EAction.Idle} className="btn btn-primary">
                                                        {this.state.action == EAction.Saving
                                                            ? <span className="spinner-border spinner-border-sm" />
                                                            : <i className="fa fa-check" />
                                                        } Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.action == EAction.DeleteConfirmation &&
                <Modal
                    size={EModalSize.SM}
                    onClickOutside={() => this.setState({ action: EAction.Idle })}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">Delete</h5>
                        <button type="button" className="btn btn-light btn-sm" onClick={() => this.setState({ action: EAction.Idle })}>
                            <i className="fa fa-times"/>
                        </button>
                    </div>
                    <div className="modal-body">
                        Are you sure?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => this.setState({ action: EAction.Idle })}><i className="fa fa-ban" /> Cancel</button>
                        <button type="button" className="btn btn-danger" onClick={() => this.onDelete()}><i className="fa fa-trash" /> Delete</button>
                    </div>
                </Modal>
                }
            </MainLayout>
        );
    }

    private renderHeader() : React.ReactNode {
        if(this.state.status == EStatus.Loaded) {
            const roleId = this.state.data.role.id;
            return (
                <div className="row align-items-center">
                    <div className="col">
                        <h3 className="m-0">{this.state.data.name}</h3>
                    </div>
                    <div className="col-auto">
                        <span className={`badge bg-${roleId == 1 ? 'success' : roleId == 2 ? 'info' : 'secondary'}`}>
                            {this.state.data.role.name}
                        </span>
                    </div>
                </div>
            );
        } else return <><span className="spinner-border spinner-border-sm text-primary" /> Details</>;
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await UserModel.details(this.props.id);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.setState({
                    status: EStatus.Loaded,
                    data: res.payload.data,
                });
                this.form.fill({
                    ...res.payload.data,
                    roleId: res.payload.data.role.id,
                    password: '',
                    passwordConfirm: '',
                }).save();
            } else this.setState({ status: EStatus.Failed });
        })();
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        this.setState({ action: EAction.Saving });
        (async () => {
            const res = await UserModel.update(this.props.id, this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully saved.',
                });
            }
            this.setState({ action: EAction.Idle });
        })();
    }

    private onDelete() : void {
        this.setState({ action: EAction.Deleting });
        (async () => {
            const res = await UserModel.delete(this.props.id);
            if(!this.isMount) return;
            if(res.success) {
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully deleted.',
                });
                App.redirect('/users');
            }
            this.setState({ action: EAction.Idle });
        })();
    }

}