import { DefaultStruct } from '@App/Structures';

export namespace EventsModelStruct {

    export interface IEvent {
        id : number,
        sensor : {
            idx : number,
            name : string,
        },
        hits : number,
        isResolved : boolean,
        tm : number,
        tmAdd : number,
        tmRec : number,
        message : string,
        typeId : number,
        value : number,
        valueTypeId : number,
        valueType : DefaultStruct.Sensors.IValueType,
    }
    export interface IEventSource {
        id : string,
        sensor : {
            idx : string,
            name : string,
        },
        hits : string,
        status : string,
        tm : string,
        tm_add : string,
        tm_rec : string,
        txt : string,
        type_id : string,
        value : string,
        value_type : string,
    }

}