import React from 'react';
import { SensorTriggersModel } from '@App/Service/Sensors/Details/Triggers/Model';
import { TriggerFormStore } from '@App/Service/Sensors/Details/Triggers/TriggerFormStore';
import { Details as SensorDetails, ETab } from '@App/Service/Sensors/Details/Details';
import { TriggerForm } from '../TriggerForm';
import { Loading } from '@Framework/Component/Loading';
import { ENotificationType } from '@Framework/Component/Notification';
import { App } from '@Framework/Core/App';
import { EModalSize, Modal } from '@Framework/Factory';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    sensorId : number,
    id : number,
}
interface IState {
    status : EStatus,
    saving : boolean,
    deleting : boolean,
    deleteConfirmation : boolean,
}

export class Details extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : TriggerFormStore = new TriggerFormStore();

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            saving: false,
            deleting: false,
            deleteConfirmation: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
        this.loadData();
    }

    public componentDidUpdate(prevProps : Readonly<IProps>) : void {
        if(this.props.sensorId != prevProps.sensorId || this.props.id != prevProps.id) this.loadData();
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        return (
            <SensorDetails id={this.props.sensorId} tab={ETab.Triggers}>
                {this.state.status != EStatus.Loaded &&
                    <div className="card-body">
                        {this.state.status == EStatus.Loading && <Loading label="Loading..." />}
                        {this.state.status == EStatus.Failed && <div className="alert alert-danger"><i className="fa fa-exclamation-triangle" /> Loading error</div>}
                    </div>
                }
                {this.state.status == EStatus.Loaded &&
                    <TriggerForm
                        form={this.form}
                        saving={this.state.saving}
                        locked={this.state.deleting}
                        onSubmit={() => this.onSubmit()}
                        controls={
                            <button
                                type="button"
                                className="btn btn-danger"
                                disabled={this.state.saving || this.state.deleting}
                                onClick={() => this.setState({ deleteConfirmation: true })}
                            >
                                {this.state.deleting ? <span className="spinner-border spinner-border-sm" /> : <i className="fa fa-trash" />}
                                {' '}
                                Delete
                            </button>
                        }
                    />
                }
                {this.state.deleteConfirmation &&
                    <Modal
                        size={EModalSize.SM}
                        onClickOutside={() => this.setState({ deleteConfirmation: false })}
                    >
                        <div className="modal-header">Delete</div>
                        <div className="modal-body">
                            Are you sure?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => this.setState({ deleteConfirmation: false })}>
                                <i className="fa fa-ban" /> Cancel
                            </button>
                            <button type="button" className="btn btn-danger" onClick={() => this.onDelete()}><i className="fa fa-trash" /> Delete</button>
                        </div>
                    </Modal>
                }
            </SensorDetails>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        (async () => {
            this.setState({ saving: true });
            const res = await SensorTriggersModel.update(this.props.sensorId, this.props.id, this.form.getValues());
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.form.fill(res.payload.data).save();
                this.setState({ saving: false });
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully saved.',
                });
            } else this.setState({ saving: false });
        })();
    }

    private onDelete() : void {
        this.setState({ deleting: true, deleteConfirmation: false });
        (async () => {
            const res = await SensorTriggersModel.delete(this.props.sensorId, this.props.id);
            if(res.success) {
                App.notification({
                    type: ENotificationType.Success,
                    title: 'Success',
                    message: 'Successfully deleted.',
                });
                if(this.isMount) App.redirect(`/sensors/${this.props.sensorId}/triggers`);
            } else if(this.isMount) this.setState({ deleting: false });
        })();
    }

    private loadData() : void {
        (async () => {
            this.setState({ status: EStatus.Loading });
            const res = await SensorTriggersModel.details(this.props.sensorId, this.props.id);
            if(!this.isMount) return;
            if(res.success && res.payload) {
                this.form.fill(res.payload.data).save();
                this.setState({ status: EStatus.Loaded });
            } else this.setState({ status: EStatus.Failed });
        })();
    }

}