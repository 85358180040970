import React from 'react';
import { Map } from 'subway-router';
import { TLoadService } from '@Framework/Core/App';
import { LoggedIn } from '@App/Middleware';
import { List } from '@App/Service/Events';
import { RangeSelector } from '@App/Component/RangeSelector';

export function register(map : Map, loadService : TLoadService) {
    map.group('/events', group => {
        group.route('/', res => {
            loadService(<List
                page={Number(res.key('page')) || 1}
                limit={Number(res.key('limit')) || 50}
                orderBy={res.key('orderBy') || undefined}
                sort={res.key('sort') || undefined}
                rangeType={RangeSelector.stringToRangeType(res.key('rangeType'))}
                dateFrom={res.key('dateFrom') ? Number(res.key('dateFrom')) : undefined}
                dateTo={res.key('dateTo') ? Number(res.key('dateTo')) : undefined}
            />);
        });
    }).name('events').middleware(new LoggedIn(loadService));
}