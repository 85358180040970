import React from 'react';
import { TagsModel, TagsModelStruct } from '@App/Service/Tags/Model';
import { observer } from 'mobx-react';
import { CreateFormStore } from '@App/Service/Tags/Create/CreateFormStore';
import { MainLayout } from '@App/Layout';
import { Link } from '@Framework/Factory';
import { Form } from '@Framework/Library/Form';
import { InputField, TextField } from '@Framework/Component/FormField';
import { App } from '@Framework/Core/App';
import { ENotificationType } from '@Framework/Component/Notification';

enum EStatus {
    Unloaded,
    Loading,
    Loaded,
    Failed,
}
interface IProps {
    type : 'groups' | 'locations'
}
interface IState {
    status : EStatus,
    data : TagsModelStruct.ITag,
    sending : boolean,
}

@observer
export class Create extends React.Component<IProps, IState> {

    private isMount : boolean = false;
    private form : CreateFormStore = new CreateFormStore();

    constructor(props) {
        super(props);
        this.state = {
            status: EStatus.Unloaded,
            data: null,
            sending: false,
        };
    }

    public componentDidMount() : void {
        this.isMount = true;
    }

    public componentWillUnmount() : void {
        this.isMount = false;
    }

    public render() : React.ReactNode {
        const name = this.props.type == 'groups' ? 'Groups' : 'Locations';
        return (
            <MainLayout service={this.props.type}>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link url="/"><a><i className="fa fa-home" /> Home</a></Link></li>
                                    <li className="breadcrumb-item"><Link url={`/${this.props.type}`}><a>{name}</a></Link></li>
                                    <li className="breadcrumb-item active">Create</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header">Create group</div>
                                <Form store={this.form} onSubmit={() => this.onSubmit()}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <InputField disabled={this.state.sending} store={this.form.fields.name} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Notes</label>
                                                    <TextField disabled={this.state.sending} store={this.form.fields.notes} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row align-items-center">
                                            <div className="col" />
                                            <div className="col-auto">
                                                {!this.form.isValid && <div className="form-notice text-danger"><i className="fa fa-exclamation-circle" /> Validation errors</div>}
                                            </div>
                                            <div className="col-auto">
                                                <button type="submit" disabled={this.state.sending} className="btn btn-primary">
                                                    {this.state.sending
                                                        ? <span className="spinner-border spinner-border-sm" />
                                                        : <i className="fa fa-check" />
                                                    } Create
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        );
    }

    private onSubmit() : void {
        this.form.validate();
        if(!this.form.isValid) return;
        (async () => {
            this.setState({ sending: true });
            const res = await TagsModel.create(this.props.type, this.form.getValues());
            if(!this.isMount) return;
            if(res.success) {
                this.form.save();
                App.notification({
                    type: ENotificationType.Success,
                    message: 'Successfully created.',
                });
                App.redirect(`/${this.props.type}`);
            } else this.setState({ sending: false });
        })();
    }

}