import { EFieldType, Field, Group, Rule } from '@Framework/Library/Form';
import { makeObservable, override } from 'mobx';

type TFields = {
    iconId : Field,
    name : Field,
    priorityId : Field,
    isActive : Field,
    groupId : Field,
    locationId : Field,
    notes : Field,
};

interface IValues {
    iconId : number,
    name : string,
    priorityId : number,
    isActive : boolean,
    groupId : number,
    locationId : number,
    notes : string,
}

export class SettingsFormStore extends Group<TFields, IValues> {

    @override protected _fields = {
        iconId: new Field({
            rules: [ Rule.required() ],
        }),
        name: new Field({
            rules: [ Rule.required() ],
        }),
        priorityId: new Field({
            type: EFieldType.Number,
            rules: [ Rule.required() ],
        }),
        isActive: new Field({
            type: EFieldType.Boolean,
        }),
        groupId: new Field({
            type: EFieldType.Number,
        }),
        locationId: new Field({
            type: EFieldType.Number,
        }),
        notes: new Field(),
    };

    constructor() {
        super(null, true);
        makeObservable(this);
    }

}