import { DefaultStruct } from '@App/Structures';

export namespace SensorTriggersStruct {

    export interface ITrigger {
        id : number,
        name : string,
        idx : string,
        timeLimit : number,
        simpleValue : boolean,
        valueNum : number,
        valueJson : string,
        typeId : number,
        type : DefaultStruct.Triggers.IType,
        level : number,
        isActive : boolean,
    }
    export interface ITriggerSource {
        id : string,
        name : string,
        sensor_idx : string,
        time_limit : string,
        value_num : string,
        value_json : string,
        type : string,
        level : string,
        active : string,
    }

}