export namespace TagsModelStruct {

    export interface ITag {
        id : number,
        name : string,
        notes : string,
    }
    export interface ITagSource {
        id : string,
        name : string,
        notes : string,
    }

}