import { IModelOptions, Model, Reply } from '@Framework/Library/Gateway';
import { EventsModelReq, EventsModelRes } from '@App/Service/Events/Model';

export abstract class EventsModel extends Model {

    public static list(
        query : EventsModelReq.List.IQuery,
        options : IModelOptions = {},
    ) : Promise<Reply<EventsModelRes.List.IPayload>> {
        return this.request<EventsModelReq.List.IQueryOutput, {}, EventsModelRes.List.IPayload>({
            path: 'events',
            query: EventsModelReq.List.getQuery(query),
            responseHandler: EventsModelRes.List.getPayload,
        }, options);
    }

}